



























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: 'pink'
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    to: {
      type: Object,
      required: true
    },
    background: {
      type: String,
      required: true
    }
  },

  setup() {}
});
