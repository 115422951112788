
































































































































































































































































































































































import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
  onMounted,
  onUnmounted
} from '@vue/composition-api';
import { useUserActions, useUserGetters } from '@/store';
import './styles/module.scss';
import { OperationMode } from '@/constants/operationMode';
import { useVideoAsk } from '@/composables/useVideoAsk';
import MBasicUserInfoDialog from '@/components/molecules/m-basic-user-info-dialog.vue';
import Bar from './components/TimelineBar.vue';

export const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export interface ITimeLineItem {
  step: string;
  unlocked: boolean;
  category: 'project' | 'internship';
  tooltip: string;
  status?: ProgramActivityStatus;
}

export default defineComponent({
  name: 'Guide',
  components: {
    'guide-bar': Bar,
    Loading: () => import('@/components/Loading.vue'),
    MModule: () => import('@/components/molecules/m-module.vue'),
    CheckoutOverlay: () => import('./components/CheckoutOverlay.vue'),
    Overlay: () => import('./components/Overlay.vue'),
    LoginDialog: () => import('@/views/Login.vue'),
    SignUp: () => import('@/views/Signup.vue'),
    MBasicUserInfoDialog
  },
  props: {
    studentId: {
      type: String,
      required: false,
      default: ''
    },
    programId: {
      type: String,
      required: false,
      default: ''
    },
    isMonitor: {
      type: Boolean,
      default: false
    },
    page: {
      type: Number,
      required: false,
      default: 1
    },
    monitorOrganizerView: {
      type: Boolean,
      required: false,
      default: false
    },
    openAsWidget: {
      type: Boolean,
      required: false,
      default: false
    },
    previewView: {
      type: Boolean,
      required: false,
      default: false
    },
    dialogOpen: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  setup(_props: any, ctx: any) {
    const windowWidth = ref(window.innerWidth);
    const updateWindowWidth = () => {
      const newWidth = window.innerWidth;
      windowWidth.value = newWidth;
    };
    const resizeObserver = new ResizeObserver(updateWindowWidth);
    onMounted(() => {
      updateWindowWidth(); // Initial width
      window.addEventListener('resize', updateWindowWidth);
      resizeObserver.observe(document.body);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowWidth);
      resizeObserver.disconnect();
    });
    const isProgramExists = ref(true);
    const isVisitor = ref(false);
    const isDraft = ref(false);
    const isSaveProgress = ref(false);
    const popUp = ref(false);
    let { programId } = _props;
    let { dialogOpen } = _props;
    const { studentId, openAsWidget, monitorOrganizerView } = _props;
    const { getUser, getProgramDoc, getUserType } = useUserGetters([
      'getUser',
      'getUserType',
      'getProgramDoc'
    ]);
    const {
      fetchProgramById,
      fetchDraft,
      fetchStudentById,
      fetchUserType,
      getProgramByNameOrIdOrSharableUrl,
      getDataWithShareableUrl,
      createStudent,
      updateCompleted
    } = useUserActions([
      'fetchProgramById',
      'fetchDraft',
      'fetchStudentById',
      'fetchUserType',
      'getProgramByNameOrIdOrSharableUrl',
      'getDataWithShareableUrl',
      'createStudent',
      'updateCompleted'
    ]);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const programDoc: Ref<{
      data: Record<string, any>; // Gives access to Document
    }> = ref({
      data: {}
    });
    /** Get program by shareable url */
    const fetchedProgramDataWithName: any = ref([]);
    const programNameParams: any = computed({
      get: () => dialogOpen,
      set: newVal => {
        dialogOpen = newVal;
      }
    });
    const programID: any = computed({
      get: () => programId,
      set: newVal => {
        programId = newVal;
      }
    });
    watch(
      () => _props.programId,
      newVal => {
        programId = newVal;
      },
      { deep: true }
    );
    if (localStorage.getItem('apollo-token')) {
      programNameParams.value = false;
    }
    if (localStorage.getItem('setRoute')) {
      localStorage.removeItem('setRoute');
    }

    const programDataBySharableUrl = ref();

    const origin = window.location.href;
    const value = origin.split('/');
    let result = value[2];
    if (process.env.VUE_APP_NODE_ENV === 'development') {
      result = 'develop.pilotcity.com';
    } else if (process.env.VUE_APP_NODE_ENV === 'stage') {
      result = 'stage.pilotcity.com';
    } else {
      result = 'pilotcity.com';
    }

    const sharable_url = `${result}/${value[3]}/${value[4]}`;

    async function getProgramDataBySharableUrl() {
      const response = await getDataWithShareableUrl({
        sharable_url
      });
      if (response && response.published) {
        programDataBySharableUrl.value = response;
        programDoc.value.data = {
          adks: [],
          ...programDataBySharableUrl.value
        };
      } else {
        ctx.root.$router.push({ name: 'landing' });
      }
    }
    if (programNameParams.value) {
      getProgramDataBySharableUrl();
    }

    const isLoginModalShow = ref(true);
    const isSignUpModalShow = ref(false);
    function showSignUpModal() {
      isSignUpModalShow.value = true;
      isLoginModalShow.value = false;
    }

    function showSignInModal() {
      isSignUpModalShow.value = false;
      isLoginModalShow.value = true;
    }

    async function fetchProgramDataByName() {
      const response = await getDataWithShareableUrl({
        sharable_url,
        document_type: 'Program'
      });
      if (response) {
        fetchedProgramDataWithName.value = response;
        programID.value = fetchedProgramDataWithName.value._id;
      }
    }

    const userDoc = ref({
      data: user.value
    });

    async function fetchProgram() {
      if (programId === '') {
        await fetchProgramDataByName();
      }
      await fetchUserType({
        program_id: programId,
        public_id: programDoc?.value?.data?.program_id ? programDoc?.value.data?._id : null
      });
      if (getUserType.value === 'organizer' && ctx.root.$route.name !== 'Monitor') {
        const draft = await fetchDraft({
          program_id: programId
        });
        isDraft.value = true;
        if (!draft) {
          isDraft.value = false;
          await fetchProgramById({
            program_id: programId
          });
        }
        programDoc.value.data = {
          adks: [],
          ...getProgramDoc.value
        };
      } else if (getUserType.value === 'participant' || ctx.root.$route.name === 'Monitor') {
        await fetchStudentById({
          program_id: programId,
          user_id: ctx.root.$route.name === 'Monitor' ? _props.studentId : user.value?._id
        });
        programDoc.value.data = {
          adks: [],
          ...getProgramDoc.value
        };
      } else {
        const userDocData = userDoc.value;
        const userDeatail: any = userDocData.data.userTypes;
        const isEmployer = userDeatail.includes('Employer');
        const isStudent = userDeatail.includes('Student');

        if (isStudent) {
          const checkProgramIsPublished = await fetchProgramById({
            program_id: programId
          });
          if (!checkProgramIsPublished?.published) {
            ctx.root.$router.push({ name: 'portfolio' });
          } else {
            const response = await createStudent({
              program_id: programId
            });
            if (response) {
              localStorage.removeItem('program');
            }
          }
        } else if (isEmployer) {
          const resp = await getProgramByNameOrIdOrSharableUrl({
            type: 'by_id',
            program_id: programId
          });
          isVisitor.value = true;
          if (resp) {
            localStorage.removeItem('program');
          } else {
            isProgramExists.value = false;
          }
        } else {
          const resp = await getProgramByNameOrIdOrSharableUrl({
            type: 'by_id',
            program_id: programId
          });
          isVisitor.value = true;
          if (resp) {
            localStorage.removeItem('program');
          }
        }
        await fetchUserType({
          program_id: programId,
          public_id: programDoc?.value?.data?.program_id ? programDoc?.value.data?._id : null
        });
        programDoc.value.data = {
          adks: [],
          ...getProgramDoc.value
        };
      }
    }

    const shouldCloseDisplayOverlay = ref(false);

    const adks = computed(() => {
      return programDoc.value?.data?.adks?.map(adk => adk.name);
    });
    const needsetup = computed(() => {
      return programDoc.value?.data?.adks?.flatMap(adk => (adk.setupRequired ? [adk.name] : []));
    });
    const maxIndex: any = computed(() => {
      return adks.value?.length - 1;
    });
    const currentPage = computed({
      get: () => _props.page,
      set: newPage => {
        if (newPage <= maxIndex.value && newPage >= 0) {
          if (openAsWidget) {
            ctx.emit('new-page', newPage);
            return;
          }
          shouldCloseDisplayOverlay.value = false;
          ctx.root.$router
            ?.replace({
              params: {
                programId,
                page: newPage.toString()
              },
              query: { ...ctx.root.$route?.query }
            })
            .catch(() => {}); // TODO: Handle error, find a better way to redirect the page instead of using setter
        }
      }
    });
    const redirectByPage = pageVal => {
      const origin = window.location.href;
      const paths = origin.split('/');
      if (paths[3] === 'program') {
        ctx.root.$router.push({
          name: 'program',
          params: {
            program_name: paths[4],
            page: pageVal
          }
        });
      } else if (currentPage.value !== maxIndex.value) {
        ctx.root.$router.push({
          name: 'guide',
          params: {
            programId,
            page: pageVal
          }
        });
      } else {
        popUp.value = true;
      }
    };
    const currentAdk: any = computed(() => {
      return adks.value ? adks.value[_props.page] : '';
    });
    const adksWithPreviewMode: any = computed(() => {
      // return process.env.VUE_APP_PREVIEW_MODE_ADKS?.split(',') || [];
      return (
        programDoc.value?.data?.adks
          ?.filter(adk => adk?.preview && adk?.status)
          ?.map(adk => adk?.name) || []
      );
    });

    const goNext = () => {
      redirectByPage(currentPage.value + 1);
    };

    const completeActivity = () => {
      const newPageNumberValue = currentPage.value + 1;
      if (currentPage.value === maxIndex.value) {
        popUp.value = true;
      } else {
        redirectByPage(newPageNumberValue);
      }
    };

    function emitMolecule(val) {
      currentAdk.value = val.item.name;
      currentPage.value = val.index;
    }
    const previewModeEnabled: any = computed(() => {
      return _props.previewView === true && getUserType.value === 'participant';
    });
    const currentUnit = computed(() => (adks.value ? adks.value[currentPage.value] : ''));
    const isInPreviewMode: any = computed(() => {
      return previewModeEnabled.value && adksWithPreviewMode.value.includes(currentUnit.value);
    });
    const currentUnitRequiresSetup = computed(() => {
      return currentUnit.value && needsetup.value?.includes(currentUnit.value);
    });

    const operationMode = computed(() => {
      if (getUserType.value === 'participant') return OperationMode.Participant;
      if (monitorOrganizerView) return OperationMode.Monitor;
      if (getUserType.value === 'organizer') return OperationMode.Setup;
      if (isInPreviewMode.value) return OperationMode.Preview;
      if (isVisitor.value) return OperationMode.Setup;
      return OperationMode.None;
    });
    const shouldDisplayOverlay = computed(() => {
      return (
        operationMode.value === OperationMode.Setup &&
        !currentUnitRequiresSetup.value &&
        !shouldCloseDisplayOverlay.value
      );
    });
    const isNextUnlocked = computed(() => {
      const adksData = programDoc.value?.data?.adks || [];
      const nextAdks = adksData?.slice(currentPage.value + 1);
      const filterNextAdks = nextAdks?.filter(adk => adk.status);
      const offerStatus = adksData[currentPage.value]?.fields?.find(
        f => f?.name === 'm-offer-table'
      )?.value?.offerStatus;
      let unlocked = false;

      // if next adk is offer and offer status false then must be locked untill offer given
      if (adksData[currentPage.value + 1] && getUserType.value !== 'organizer') {
        const isOfferMolecule = adksData[currentPage.value + 1]?.fields?.find(
          field => field.name === 'm-offer-table'
        );
        if (isOfferMolecule) {
          if (
            programDoc?.value?.data?.applicationStatus === 'accepted' &&
            programDoc?.value?.data?.offerDetails
          ) {
            return true;
          }
          return false;
        }
      }

      if (adksData.length) {
        switch (adksData[currentPage.value]?.name) {
          case 'interview':
            unlocked =
              getUserType.value === 'organizer' ? true : adksData[currentPage.value]?.completed;
            break;

          case 'offer':
            unlocked =
              getUserType.value === 'organizer'
                ? true
                : adksData[currentPage.value]?.completed &&
                  programDoc.value?.data.offerDetails &&
                  offerStatus;
            break;

          default:
            unlocked =
              getUserType.value === 'organizer' ? true : !!adksData[currentPage.value]?.completed;
            break;
        }
        return unlocked;
      }

      if (
        (adksData.length &&
          adksData[currentPage.value]?.completed &&
          filterNextAdks?.length >= 1) ||
        getUserType.value === 'organizer'
      ) {
        return true;
      }
      return false;
    });
    const nextPage = async () => {
      let position = currentPage.value + 1;
      const adksData = programDoc.value.data.adks;
      const nextAdks = adksData.slice(position);
      for (let i = 0; i < nextAdks.length; i++) {
        if (!nextAdks[i].status || nextAdks[i].isAdkDeleted) {
          position += 1;
        } else {
          break;
        }
      }
      currentPage.value = position;
      // currentAdk.value = adks.value[position];
    };
    const prevPage = pageValue => {
      let position = pageValue - 1;
      const adksData = programDoc.value.data.adks;
      const prevAdks: any = [];
      for (let k = position; k >= 0; k--) {
        prevAdks.push(adksData[k]);
      }

      if (currentPage.value !== 0) {
        for (let j = 0; j < prevAdks.length; j++) {
          if (!prevAdks[j].status || prevAdks[j].isAdkDeleted) {
            position -= 1;
          } else {
            break;
          }
        }
      }
      currentPage.value = position;
    };

    const checkPrevAdk = (adksData, position) => {
      let p = position;
      const prevAdks: any = [];
      for (let k = position; k >= 0; k--) {
        prevAdks.push(adksData[k]);
      }
      for (let j = 0; j < prevAdks.length; j++) {
        if (!prevAdks[j].status || prevAdks[j].isAdkDeleted) {
          p -= 1;
        } else {
          break;
        }
      }
      const adk = programDoc.value?.data?.adks[p];
      return adk;
    };

    // Check current adk completed or not and also check for offer adk if not redirect to previous adk
    watch(currentAdk, async () => {
      if (currentAdk.value) {
        if (getUserType.value === 'participant') {
          const adkDataValue = programDoc.value?.data?.adks?.find(
            adk => adk.name === currentAdk.value
          );
          // const offerAdkIndex = programDoc.value?.data?.adks?.findIndex(
          //   adk => adk.name === 'offer'
          // );
          const offerAdkIndex = programDoc?.value?.data?.adks?.findIndex(adk =>
            adk.fields?.find(field => field.name === 'm-offer-table')
          );
          const offerAdkMolecule = programDoc.value?.data?.adks[offerAdkIndex]?.fields.find(
            field => field.name === 'm-offer-table'
          );
          if (
            offerAdkIndex > 0 &&
            currentPage.value >= offerAdkIndex &&
            !programDoc.value?.data?.offerDetails &&
            !isInPreviewMode.value
          ) {
            prevPage(offerAdkIndex);
          } else if (
            offerAdkIndex > 0 &&
            currentPage.value >= offerAdkIndex &&
            programDoc.value?.data?.offerDetails &&
            !programDoc.value?.data?.adks[offerAdkIndex]?.completed &&
            !offerAdkMolecule?.value?.offerStatus &&
            !isInPreviewMode.value
          ) {
            prevPage(offerAdkIndex + 1);
          }
          const prevAdkValue = checkPrevAdk(programDoc.value?.data?.adks, currentPage.value - 1); // programDoc.value?.data?.adks[currentPage.value - 1];
          if (prevAdkValue && !prevAdkValue.completed && !isInPreviewMode.value) {
            prevPage(currentPage.value);
          }
          if (currentAdk.value === 'results') {
            const result = programDoc.value?.data?.adks?.find(elem => elem.name === 'results');
            const filterIsUpdated = programDoc.value?.data?.adks?.filter(elem => elem.completed);
            if (filterIsUpdated.length === programDoc.value?.data?.adks?.length - 1) {
              await updateCompleted({
                adkIndex: result.position,
                collection_name: 'Student',
                name: 'results',
                program_id: programDoc.value?.data?._id,
                isCompleted: true
              });
              fetchProgram();
            }
          }
        }
      } else {
        ctx.root.$router.push({
          params: {
            programId,
            page: 0
          }
        });
      }
    });

    const finishedSetup = (adkName: string) => {
      if (adkName === 'start') {
        return (
          programDoc.value?.data?.start_activity_status === ProgramActivityStatus.CompleteSetup
        );
      }
      // TODO: remove this when setup mode for Interview is implemented
      if (adkName === 'interview') {
        return true;
      }
      return (
        programDoc.value?.data?.adks?.find(adk => {
          if (adk) {
            return adk.name === adkName;
          }
          return false;
        })?.status === ProgramActivityStatus.CompleteSetup
      );
    };

    const stepsRequireSetupCompletePercentage = computed(() => {
      const totalStepsRequireSetup = needsetup.value?.length;
      let completedSteps = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const stepName of needsetup.value || []) {
        if (finishedSetup(stepName)) {
          completedSteps += 1;
        }
      }

      return Math.floor((completedSteps / totalStepsRequireSetup) * 100);
    });

    //! Doc Creation
    const containerClass = computed(() => {
      return {
        guide__page_organizer: operationMode.value === OperationMode.Setup,
        guide__page_participant: operationMode.value === OperationMode.Participant,
        guide__page_monitor: operationMode.value === OperationMode.Monitor,
        guide__preview_view: operationMode.value === OperationMode.Preview,
        'almost-done':
          stepsRequireSetupCompletePercentage.value > 51 &&
          stepsRequireSetupCompletePercentage.value < 99,
        done: stepsRequireSetupCompletePercentage.value === 100
      };
    });

    // const userDoc = ref({
    //   data: user.value
    // });
    watch(user, () => {
      userDoc.value.data = user.value;
    });

    const handleCloseOverlay = () => {
      shouldCloseDisplayOverlay.value = true;
    };

    const lastSetupPage = () => {
      for (let i = currentPage.value; i >= 0; i--) {
        const targetAdk = adks.value[i];
        if (needsetup.value.includes(targetAdk)) {
          currentPage.value = i;
          currentAdk.value = adks.value[i];
          break;
        }
      }
    };
    const nextSetupPage = () => {
      const currentPageIndex = adks.value.indexOf(currentUnit.value);
      for (let i = currentPageIndex; i < adks.value.length; i++) {
        const targetAdk = adks.value[i];

        if (needsetup.value.includes(targetAdk)) {
          currentPage.value = i;
          currentAdk.value = adks.value[i];
          break;
        }
      }
    };
    const handleChangeStep = (action: 'last-setup' | 'next-setup' | 'last' | 'next') => {
      switch (action) {
        case 'last-setup':
          lastSetupPage();
          break;
        case 'next-setup':
          nextSetupPage();
          break;
        case 'last':
          prevPage(currentPage.value);
          break;
        case 'next':
          nextPage();
          break;
        default:
          break;
      }
    };
    const programName = computed(() => {
      return programDoc.value?.data?.programName || 'Employer Name';
    });

    const pushHome = () => {
      ctx.root.$router.push({ name: 'portfolio' });
    };
    // const operationMode = computed(() => {
    //   return OperationMode.Monitor;
    // });

    const userType = computed(() => {
      let userType: 'organizer' | 'stakeholder' | 'participant' | '' = 'stakeholder';

      if (ctx.root.$route.query && ctx.root.$route.query.program) {
        userType = 'participant';
      }
      return userType;
    });
    useVideoAsk(user, operationMode, userType);

    const progressValue = ref(0);
    const animationDelay = ms => new Promise(res => setTimeout(res, ms));

    async function fetchProgress() {
      await fetchProgram();
      await animationDelay(800);
      const adksData = programDoc.value?.data?.adks;
      const availableAdks = adksData.filter(adk => !adk.isAdkDeleted);
      const completedAdks = availableAdks.filter(adk => adk.completed);
      progressValue.value = (completedAdks.length / availableAdks.length) * 100;
    }
    fetchProgress();

    const emphasize = ref(false);
    async function animateProgress(mode = 'load') {
      if (mode === 'load') {
        await animationDelay(1200);
        emphasize.value = !emphasize.value;
        await fetchProgress();
      }
      if (mode === 'complete') {
        const innerScroll = this.$refs.scrollable;
        innerScroll.scrollTo({ top: 0, behavior: 'smooth' });
        window.scrollTo({ top: 0, behavior: 'smooth' });
        progressValue.value = 0;
        emphasize.value = false;
        await animationDelay(10);
        emphasize.value = true;
        fetchProgress();
      }
    }
    animateProgress();

    const isPublicProgram = computed(() => {
      return ctx.root.$route.name === 'publicProgram';
    });

    const showBasicUserInfoDialog = ref(false);

    // Watch for route changes to control dialog visibility
    watch(
      isPublicProgram,
      newVal => {
        if (newVal) {
          showBasicUserInfoDialog.value = true;
        }
      },
      { immediate: true }
    );

    const handleUserInfoSubmit = async () => {
      try {
        // Handle the submitted user info
        // You might want to store this temporarily or process it immediately
        // depending on your requirements
        showBasicUserInfoDialog.value = false;
      } catch (error) {
        console.error('Error handling user info submission:', error);
      }
    };

    return {
      currentUnit,
      getProgramDoc,
      goNext,
      completeActivity,
      maxIndex,
      currentPage,
      fetchProgram,
      programDoc,
      adks,
      userDoc,
      needsetup,
      adksWithPreviewMode,
      isInPreviewMode,
      previewModeEnabled,
      operationMode,
      OperationMode,
      user,
      emitMolecule,
      finishedSetup,
      stepsRequireSetupCompletePercentage,
      containerClass,
      currentAdk,
      shouldCloseDisplayOverlay,
      shouldDisplayOverlay,
      isNextUnlocked,
      nextPage,
      prevPage,
      handleCloseOverlay,
      handleChangeStep,
      programName,
      getUserType,
      // fetchUserRole,
      isVisitor,
      isSaveProgress,
      programNameParams,
      showSignUpModal,
      isSignUpModalShow,
      isLoginModalShow,
      showSignInModal,
      isProgramExists,
      pushHome,
      isDraft,
      fetchProgress,
      progressValue,
      emphasize,
      animateProgress,
      windowWidth,
      popUp,
      showBasicUserInfoDialog,
      handleUserInfoSubmit,
      isPublicProgram
    };
  }
  // REMOVED ZENDESK FOR NOW - OCTOBER 25TH, 2023 - Derick Lee
  // mounted() {
  //   const page = document.getElementsByTagName('head')[0];

  //   const zendesk_chat = document.createElement('script');
  //   zendesk_chat.setAttribute(
  //     'src',
  //     'https://static.zdassets.com/ekr/snippet.js?key=10f98f25-6f65-409a-a7cf-c1c1a0e2f58b'
  //   );
  //   zendesk_chat.setAttribute('id', 'ze-snippet');
  //   page?.appendChild(zendesk_chat);
  // }
});
