


































































































































































































import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'StoriesGrid',
  setup() {
    const memberColors = ref(new Map());

    const colors = [
      '#6eba80', // green
      '#3c9dcd', // blue
      '#ea6764', // red
      '#eda1bf', // pink
      '#fec34b', // yellow
      '#ae90b0', // purple
      '#f79961' // orange
    ];

    const getMemberColor = (member: any) => {
      if (!memberColors.value.has(member.name)) {
        memberColors.value.set(member.name, colors[Math.floor(Math.random() * colors.length)]);
      }
      return memberColors.value.get(member.name);
    };

    const getLighterColor = (color: string, amount = 0.2) => {
      if (!color) return '';

      const hex = color.replace('#', '');
      const r = parseInt(hex.substr(0, 2), 16);
      const g = parseInt(hex.substr(2, 2), 16);
      const b = parseInt(hex.substr(4, 2), 16);

      const newR = Math.min(Math.round(r + (255 - r) * amount), 255);
      const newG = Math.min(Math.round(g + (255 - g) * amount), 255);
      const newB = Math.min(Math.round(b + (255 - b) * amount), 255);

      return `rgb(${newR}, ${newG}, ${newB})`;
    };

    const calculateFontSize = (text: string, hasAvatar = false) => {
      const { length } = text;
      const isMobile = window.innerWidth < 600;
      const isTablet = window.innerWidth < 960;

      // Mobile font sizes
      if (isMobile) {
        if (length > 200) return '0.75rem';
        if (length > 150) return '0.8rem';
        if (length > 100) return '0.85rem';
        if (length > 50) return '0.9rem';
        return '1rem';
      }

      // Tablet font sizes
      if (isTablet) {
        if (length > 200) return '0.8rem';
        if (length > 150) return '0.9rem';
        if (length > 100) return '1rem';
        if (length > 50) return '1.1rem';
        return '1.2rem';
      }

      // Desktop font sizes (original logic)
      if (hasAvatar) {
        if (length > 200) return '0.85rem';
        if (length > 150) return '1rem';
        if (length > 100) return '1.2rem';
        if (length > 50) return '1.4rem';
        return '1.6rem';
      }

      if (length > 200) return '0.9rem';
      if (length > 150) return '1.1rem';
      if (length > 100) return '1.3rem';
      if (length > 50) return '1.5rem';
      return '1.7rem';
    };

    const teamMembers = [
      {
        name: 'Eric Reyes',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/EricReyes.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Jerold Inocencio',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JeroldInocencio.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Kura Peng',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KuraPeng.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Parul Veda',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ParulVeda.png',
        description:
          'Parul now studies at New York University, engaging with her studies and pursuing her ambitions.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Aaron Brown',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AaronBrown.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Abizer Varawala',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AbizerVarawala.png',
        description:
          'Abizer now studies Computer Science at UC Santa Cruz, focusing on coding and problem-solving skills.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Ayan Shah',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AyanShah.png',
        description:
          'Ayan now studies Economics and Business Administration with a concentration in Finance, preparing to lead in financial analysis and strategy.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Huaxuan Yang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HuaxuanYang.png',
        description:
          'Huaxuan now is studying Computer Engineering at UC Santa Cruz, developing technical skills for his future in engineering.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Noah MacLean',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NoahMacLean.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Prajit Saravanan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PrajitSaravanan.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Riddhi Bhandari',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RiddhiBhandari.png',
        description:
          'Riddhi now is advancing her knowledge in Marketing and E-commerce, preparing to make an impact in digital business.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Samantha Wilcox',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SamanthaWilcox.png',
        description:
          'Samantha now studies Graphic Design, where she is building skills to create visually engaging content.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Tim Deloney',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/TimDeloney.png',
        description:
          'Tim now attends UC Merced, focusing on entrepreneurship and computer science within his business studies.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Alexandra Arient',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlexandraArient.png',
        description:
          "Alexandra now attends UC Santa Cruz, where she's diving into her studies and discovering new academic interests.",
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Areeba Asaduzzaman',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AreebaAsaduzzaman.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Bridgette Lai',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/BridgetteLai.png',
        description:
          'Bridgette now is at UC Berkeley, diving into Cognitive Science and exploring multidisciplinary classes that broaden her worldview.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Devarsh Jayakrishnan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/DevarshJayakrishnan.png',
        description:
          'Devarsh now is studying Computer Science and Data Science, with a passion for understanding and utilizing data.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Eric Xie',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/EricXie.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Erik Pedersen',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ErikPedersen.png',
        description:
          'Erik now studies Computer Science at Cornell, working on productivity projects he hopes to publish or expand into an organization.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Hannah Levin',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HannahLevin.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Harsh Sheth',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HarshSheth.png',
        description:
          'Harsh now pursues Computer Science and Data Science, preparing to solve complex data-driven problems.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Kevin Dinh',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KevinDinh.png',
        description:
          'Kevin now attends California State University Long Beach, continuing his academic journey and building on his passions.',
        before:
          'At the time, I was very unconfident. I had a lot of failures up to that point, with no clear path, and felt lost.',
        after:
          'I overcame a lot of anxieties and stresses and found a way to make an impact in my community.',
        best: 'PilotCity was a time for me to really reflect and embrace my people skills, overcoming anxieties and finding a purpose, not just for myself but for my community.'
      },
      {
        name: 'Kyara Garner',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KyaraGarner.png',
        description:
          'Kyara now pursues Studio Art, honing her creativity and developing her artistic expression.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Lorenzo Uk',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/LorenzoUk.png',
        description:
          'Lorenzo now studies Computer Science with a focus on Game Design, developing skills to create immersive digital worlds.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Nick Falk',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NickFalk.png',
        description:
          'Nick now is pursuing Quantitative Analysis of Markets and Organizations at the University of Utah, sharpening his skills in market dynamics.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Sophia Zhu',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SophiaZhu.png',
        description:
          'Sophia now studies Computer Science at UCSD, working on a river cross-section modeling project that deepens her understanding of geographic data and collaboration across universities.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Stephanie Betancourt',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/StephanieBetancourt.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Thryambak Ganapathy',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ThryambakGanapathy.png',
        description: '',
        before:
          "I felt I wasn't making a significant contribution to the real world, despite good grades and schoolwork.",
        after:
          'PilotCity gave me a way to use my skills for real-world impact, helping disadvantaged communities and building my professional communication skills.',
        best: 'PilotCity gave me a way to use my skills for real-world impact, helping disadvantaged communities and building my professional communication skills.'
      },
      {
        name: 'Vaibhav Jayakumar',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/VaibhavJayakumar.png',
        description: '',
        before:
          'I was terrified of stepping outside my comfort zone and unsure of finding a place in my community.',
        after:
          'I learned to grow by stepping outside my comfort zone, networking, and even working abroad with confidence.',
        best: 'PilotCity taught me that stepping outside my comfort zone was the only way to grow and become a better person.'
      },
      {
        name: 'Aadhvika Koundinya',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AadhvikaKoundinya.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Adhvaith Ravi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AdhvaithRavi.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Akheel Shaik',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AkheelShaik.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Alec McGhie',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlecMcGhie.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Alexis Quan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlexisQuan.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Darius Stowe',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/DariusStowe.png',
        description:
          'Darius now is studying Business Management and Entrepreneurship at Cal State Fullerton, preparing for a future in business leadership.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Elon Hufana',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ElonHufana.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Erin Lam',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ErinLam.png',
        description:
          'Erin now is diving into Physics and Art, discovering the connections between science and creativity.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Johnathan Sutan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JohnathanSutan.png',
        description:
          'Johnathan now attends UC Berkeley, pursuing Data Science, where the variety of classes is opening up his perspective.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Katrina Castro',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KatrinaCastro.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Kelly Hu',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KellyHu.png',
        description:
          'Kelly now is exploring Civil Engineering and Architecture, where she combines technical skill with design creativity.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Khushi Matpadi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/KhushiMatpadi.png',
        description:
          'Khushi now is at San Jose State University, completing her BS in Management while preparing for grad school applications and building a foundation for her career after graduation.',
        before: '',
        after: '',
        best: ''
      },

      // {
      //   name: 'Shifanaaz Fazalur',
      //   image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ShifanaazFazalur.png',
      //   description:
      //     'Shifanaaz now attends UC Davis, studying Computer Science and building a network that connects her to invaluable opportunities, fueling her motivation.',
      //   before: '',
      //   after: '',
      //   best: ''
      // },
      {
        name: 'Marley Maduakolam',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/MarleyMaduakolam.png',
        description:
          'Marley now is studying at Cal State East Bay, expanding his knowledge and preparing for his future career.',
        before: 'I felt invisible, like many students with disabilities.',
        after:
          'I went from being invisible to being visible, sharing hope and advocating for students with disabilities.',
        best: 'PilotCity took me from feeling invisible to visible, using technology to bring awareness and voice to students with disabilities.'
      },
      {
        name: 'Mrinmayee Reddy',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/MrinmayeeReddy.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Nikhil Gupta',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NikhilGupta.png',
        description:
          'Nikhil now is at UCLA, engaging deeply in his studies and expanding his knowledge base.',
        before:
          "I had a linear approach to problems and didn't think of myself as creative or entrepreneurial.",
        after:
          'Through PilotCity, I developed out-of-the-box thinking, entrepreneurial tendencies, and started working on my own startups.',
        best: 'PilotCity developed my entrepreneurial thinking, transforming me from a linear to an out-of-the-box thinker.'
      },
      {
        name: 'Niya Panchumarthi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NiyaPanchumarthi.png',
        description: '',
        before:
          'I was a fifth-grader interested in programming but with little understanding of the development process.',
        after:
          'Through structured milestones and feedback, I gained confidence in presenting and learned what it takes to be a software developer.',
        best: 'The structured milestones and feedback from industry professionals helped me understand what it means to be a software developer.'
      },
      {
        name: 'Nolan Tsung',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NolanTsung.png',
        description:
          'Nolan now pursues Psychology at San Jose State University, deepening his understanding of human behavior.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Phoebe Bang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PhoebeBang.png',
        description:
          'Phoebe now is focused on Pharmacology and Oncology, studying to make strides in cancer research and treatment.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Pranav Sundar',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PranavSundar.png',
        description:
          'Pranav now is pursuing Neuroscience at UC Santa Cruz, delving into the complexities of the brain.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Pranay Rishi Nalem',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PranayRishiNalem.png',
        description:
          'Pranay now is exploring Computer Science and Entrepreneurship, blending tech skills with a business mindset.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Princess Johanna Eusantos',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PrincessJohannaEusantos.png',
        description:
          'Princess now is at Skyline College, where she’s gaining new insights and exploring her academic potential.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Riya Lal',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RiyaLal.png',
        description:
          'Riya now explores Psychology with an interest in medicine and psychiatry, driven to understand and support mental health.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Rohit Mamidipaka',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RohitMamidipaka.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Stephanie Li',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/StephanieLi.png',
        description:
          'Stephanie now pursues Engineering, focusing on building skills for a future in innovation.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Yulin Lin',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/YulinLin.png',
        description:
          'Yulin now is at the University of California, Merced, embracing new academic challenges and exploring his interests.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Aishwarya Karthikraja',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AishwaryaKarthikraja.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Ajane Chavez',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AjaneChavez.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Alyssa Cheng',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AlyssaCheng.png',
        description:
          'Alyssa now is considering a major in Environmental Science, driven to make a difference in sustainability.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Aman Nalem',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AmanNalem.png',
        description: '',
        before: 'I was shy and struggled with communication, often procrastinating on assignments.',
        after:
          'PilotCity improved my communication and programming skills and taught me not to procrastinate on big projects.',
        best: 'PilotCity made me a better communicator and helped me overcome procrastination with big projects.'
      },
      {
        name: 'Aprajit Vijayaraghavan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AprajitVijayaraghavan.png',
        description: '',
        before:
          'I was just an ordinary person, with little understanding or empathy for the experiences of disabled individuals.',
        after:
          'I became more empathetic, especially towards disabled individuals, understanding the effort required for them to communicate and interact.',
        best: 'The program opened my eyes to the effort it takes for disabled individuals to communicate, making me a more empathetic person overall.'
      },
      {
        name: 'Atharv Rajure',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AtharvRajure.png',
        description:
          'Atharv now is studying Engineering, Computer Science, Bio-Engineering, and Aeronautical Engineering, laying a foundation for an interdisciplinary career in technology and medicine.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Ayush Kansal',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AyushKansal.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Hoi Lam Ling',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/HoiLamLing.png',
        description:
          'Hoi Lam now studies Biomedical Engineering and Programming, aiming to innovate at the intersection of technology and healthcare.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Jacob Gardanier',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JacobGardanier.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Joey Choi',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JoeyChoi.png',
        description: '',
        before:
          "I felt isolated and hadn't experienced an internship that offered true learning or collaboration.",
        after:
          'PilotCity showed me what a supportive internship is like and provided connections that inspired my future in smart cities.',
        best: 'PilotCity showed me what a good internship is, offering real connections and inspiring me to pursue smart city initiatives.'
      },
      {
        name: 'Romney Cola',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RomneyCola.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Shahir Ali',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ShahirAli.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Tyler Wang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/TylerWang.png',
        description:
          'Tyler now is immersed in Engineering and Physics, exploring the complexities of technical and scientific challenges.',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Aaditya Raj',
        image: '',
        description: '',
        before:
          'I was used to working solo on software projects and was unsure of my ability to collaborate on such projects.',
        after:
          'After PilotCity, I developed collaborative skills and became a better team player, which transformed how I work.',
        best: 'PilotCity taught me how to collaborate and deliver software projects with a team, which was new and transformative for me.'
      },
      {
        name: 'Akhil Gupta',
        image: '',
        description: '',
        before:
          'I lacked experience and guidance to use my passion for technology and entrepreneurship effectively.',
        after:
          'PilotCity helped me grow my creativity and teamwork skills, building on my project and executing my ideas with confidence.',
        best: 'PilotCity took my passion for technology and entrepreneurship to the next level, helping me grow my creativity and teamwork skills.'
      },
      {
        name: 'Akshra Narahari',
        image: '',
        description:
          'Akshra now is studying at San Jose State University, building on her skills and knowledge in her chosen field.',
        before:
          'I was introverted, with limited technical skills and experience in professional settings.',
        after:
          'PilotCity helped me become a stronger communicator and gain real-life experience in teamwork and professionalism.',
        best: 'PilotCity gave me a life-changing experience in professionalism and teamwork, helping me expand my horizons and become a stronger communicator.'
      },
      {
        name: 'Alan Chian',
        image: '',
        description: '',
        before:
          'I had a naive view of work, thinking it was similar to school with straightforward problems and single solutions.',
        after:
          'I embraced ambiguity, creativity, and learned to present multiple solutions, boosting my confidence and public speaking skills.',
        best: 'PilotCity taught me to embrace ambiguity, find solutions that fit me, and lose the fear of making mistakes.'
      },
      {
        name: 'Apuroop Padamatinti',
        image: '',
        description: '',
        before:
          'I struggled with procrastination, often postponing tasks and avoiding asking questions.',
        after:
          'Through PilotCity, I improved my time management, set clear deadlines, and became confident in asking questions and seeking help.',
        best: 'This program helped me overcome procrastination, improve time management, and confidently ask questions.'
      },
      {
        name: 'Arshia Sharma',
        image: '',
        description:
          'Arshia now is a pre-business major at UC Riverside, where a range of intriguing college classes keeps her inspired to learn more.',
        before:
          'I was skeptical about internships, feeling I had no basis or idea of what to expect.',
        after:
          'After PilotCity, I feel motivated to explore more opportunities and keep trying out new things with encouragement.',
        best: 'PilotCity motivated me to explore more opportunities and encouraged me to keep trying new things.'
      },
      {
        name: 'Carolyn Hom',
        image: '',
        description: '',
        before:
          'I had not worked with strangers to complete a shared goal and found it awkward to collaborate effectively.',
        after:
          'Now, I have experience in working with others productively and understand how real-world collaboration works.',
        best: 'PilotCity taught me how to work together productively with others, gaining real-world teamwork experience.'
      },
      {
        name: 'Gavin Torrecampo',
        image: '',
        description: '',
        before:
          'I had little idea of what an office meeting, presentation, or extended coworker communication would be like.',
        after:
          'After PilotCity, I’m more confident communicating with managers and coworkers, a skill I now value even more than technical skills.',
        best: 'PilotCity gave me confidence in communicating with managers and coworkers, a skill I value more than technical skills.'
      },
      {
        name: 'Sanjana Chaudhari',
        image: '',
        description: '',
        before:
          'I was a normal high schooler with little knowledge of the business world and was more reserved.',
        after:
          'PilotCity made me more confident, helped me communicate more effectively, and taught me practical skills like customer engagement.',
        best: 'PilotCity gave me a taste of the real world and enhanced my communication and customer engagement skills, which I couldn’t learn in school.'
      },
      {
        name: 'Yash Dighe',
        image: '',
        description: '',
        before:
          'I had little experience in professional settings and no idea of how real teamwork or task management worked.',
        after:
          'PilotCity showed me a glimpse of professionalism, inspired better effort in my work, and made me apply skills more thoughtfully.',
        best: 'PilotCity gave me a glimpse into a professional environment and inspired me to put more effort into my work.'
      },

      {
        name: 'Sean Morgan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SeanMorgan.png',
        description: '',
        before: '',
        after: '',
        best: ''
      },
      {
        name: 'Praneel Baskar',
        image: '',
        description: '',
        before:
          'I thought opportunities were something given to you, and you could just take advantage of them.',
        after:
          "I now understand that opportunities are everywhere, and it's about how you see and interact with the world to discover them.",
        best: 'Opportunities aren’t just given; they’re scattered everywhere. PilotCity opened my mind to discovering and creating my own opportunities.'
      },
      {
        name: 'Timothy Fong',
        image: '',
        description: '',
        before:
          "I was indecisive, unsure how to expand my creativity, and didn't fully understand video editing.",
        after:
          'I now know how to push my creativity and express myself, expanding my skills and exploring new ways of self-expression.',
        best: 'Through PilotCity, I learned how to push my creativity and express myself in videos, expanding my skills and self-understanding.'
      },
      {
        name: 'Renfei Xu',
        image: '',
        description: '',
        before:
          "I had no experience in electronics, didn't know how to solder or what a resistor was.",
        after:
          'I gained a great appreciation for electronics, learning STEM, improving communication skills, and boosting my public speaking confidence.',
        best: 'This program gave me invaluable work experience and sparked my passion for electronics, STEM, and public speaking.'
      },
      {
        name: 'Kanishk Choudhary',
        image: '',
        description: '',
        before:
          'I knew how to code but was inexperienced with working in teams and navigating a real workplace.',
        after:
          'After the program, I felt confident in collaborating with others and understanding how code is used in real work environments.',
        best: 'I understood how code is implemented in the workplace and how to confidently work and collaborate with others.'
      },
      {
        name: 'Ish Talegaonkar',
        image: '',
        description: '',
        before: "I didn't have direction and wasn't sure what I wanted to do or where I was going.",
        after:
          'After PilotCity, I had a clearer direction and a sense of purpose, knowing my work was making an impact.',
        best: 'PilotCity gave me a sense of direction and a purpose in my work that goes beyond just doing something I enjoy.'
      },
      {
        name: 'Daniel Bonner',
        image: '',
        description:
          'Daniel now is focused on Computer Science, Programming, and Game Development, ready to bring his creativity to interactive media.',
        before:
          "I hadn't had much experience working in a team or following a structured schedule with deadlines.",
        after:
          'Through the internship, I learned to work with people and meet deadlines, seeing the impact of my contributions beyond the classroom.',
        best: 'I gained experience in teamwork, deadlines, and communication, making a real impact beyond just a classroom assignment.'
      },
      {
        name: 'Prashanth Prabhala',
        image: '',
        description: '',
        before:
          'I had interest in computer science and business but no real experience in those fields.',
        after:
          'The program allowed me to work in business and coding, fueling my interests and honing my skills in the industry.',
        best: 'PilotCity was a great experience, enhancing my computer science skills and sparking a focus on AI.'
      },
      {
        name: 'Neel Sai Panchumarthi',
        image: '',
        description: '',
        before:
          "I didn't have much experience in a professional setting or an understanding of real-world applications of technology.",
        after:
          'PilotCity gave me real job experience, a sense of time management, and the ability to apply technology to make an impact.',
        best: 'PilotCity gave me real job experience and inspired me to use technology to make an impact.'
      },
      {
        name: 'Poorv Patil',
        image: '',
        description: '',
        before:
          "I hadn't been exposed to a workplace setting or worked under deadlines that impacted others.",
        after:
          'I learned workplace skills, teamwork, and public speaking, boosting my confidence and communication abilities.',
        best: 'PilotCity exposed me to a workplace environment, deadlines, and public speaking, transforming me into a more confident communicator.'
      },
      {
        name: 'Leon Malthilde Beckman-Forman',
        image: '',
        description:
          'Leon now is attending San Joaquin Delta College, advancing his education and exploring new opportunities.',
        before:
          "I wasn't interested in internships and didn't understand teamwork in a professional setting.",
        after:
          "PilotCity taught me teamwork, leadership, and how to deliver work according to my employer's vision, which boosted my confidence.",
        best: 'I learned the importance of delivering for an employer and improved my public speaking and teamwork skills.'
      },
      {
        name: 'Rahul Rejeev',
        image: '',
        description: '',
        before:
          "I didn't have experience working with a group of different age ranges and didn't know much about the real world.",
        after:
          'PilotCity gave me real-world experience, teamwork skills, and hands-on learning that boosted my confidence.',
        best: 'PilotCity gave me real-world experience, teamwork skills, and hands-on learning that boosted my confidence.'
      },
      {
        name: 'Shannon Lo',
        image: '',
        description:
          'Shannon now studies at San Jose State University, continuing her academic journey and career preparation.',
        before:
          'I lacked confidence and experience working with new people and managing an internship.',
        after:
          'PilotCity gave me confidence and taught me how to manage deliverables even in challenging circumstances like time zone differences.',
        best: 'Working in PilotCity made me much more confident in myself and my abilities, helping me manage deliverables despite time zone challenges.'
      },
      {
        name: 'Roshan Jakkilinki',
        image: '',
        description: '',
        before: "I wasn't as confident and doubted my abilities to make a difference.",
        after:
          'PilotCity boosted my confidence, taught me I have the power to make a community impact, and improved my public speaking skills.',
        best: 'PilotCity boosted my confidence and showed me I have the power to make a difference in the community and in projects.'
      }
    ];

    const shuffleArray = (array: any[]) => {
      const newArray = [...array];
      for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = newArray[i];
        newArray[i] = newArray[j];
        newArray[j] = temp;
      }
      return newArray;
    };

    // Shuffle the team members array before returning it
    const shuffledTeamMembers = shuffleArray(teamMembers);

    const getFirstName = (fullName: string) => {
      return fullName.split(' ')[0];
    };

    return {
      teamMembers: shuffledTeamMembers,
      getMemberColor,
      getLighterColor,
      calculateFontSize,
      getFirstName
    };
  }
});
