






import { useUserActions, useUserState, useUserGetters } from '@/store';
import { watchEffect } from '@vue/composition-api';
import activitiesList from './const';

export default {
  setup(props, ctx: any) {
    // const { collection } = useDbGetters(['collection']);
    const { getLatestDocumentByUserId } = useUserActions(['getLatestDocumentByUserId']);
    const { user }: any = useUserState(['user']);
    async function redirect() {
      // collection.value!('Student')
      //   .findOne({ participant_id: user.value?._id }, { sort: { created: -1 } })
      //   .then(res => {
      //     if (res) {
      //       const setupAdks = res.adks.filter(
      //         adk =>
      //           adk.completed ||
      //           adk.isComplete ||
      //           adk.discordIntegraionSkipped ||
      //           adk.directoryPreferences
      //       );
      //       const mapedSetupAdks = setupAdks.map(adk => adk.name);

      //       const adks = activitiesList;
      //       let index = 0;
      //       for (let k = 0; k < adks.length; k++) {
      //         if (mapedSetupAdks.includes(adks[k])) {
      //           index = adks.indexOf(adks[k]);
      //         }
      //       }
      const res = await getLatestDocumentByUserId({
        document_type: 'Student',
        user_id: user.value?._id.toString()
      });
      if (
        res &&
        res?.document &&
        res?.lastCompletedAdkIndex !== undefined &&
        res?.lastCompletedAdkIndex !== null
      ) {
        ctx.root.$router.push({
          name: 'guide',
          params: {
            programId: res.document.program_id.toString(),
            page: `${res.lastCompletedAdkIndex}`
          }
        });
      } else {
        ctx.root.$router.push({ name: 'portfolio' });
      }
      // });
    }

    watchEffect(async () => {
      if (user.value?._id) {
        await redirect();
      }
    });

    return { redirect };
  }
};
