var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.isPublicProgram)?_c('m-basic-user-info-dialog',{attrs:{"force-display":true},on:{"submit":_vm.handleUserInfoSubmit},model:{value:(_vm.showBasicUserInfoDialog),callback:function ($$v) {_vm.showBasicUserInfoDialog=$$v},expression:"showBasicUserInfoDialog"}}):_vm._e(),(_vm.getProgramDoc && _vm.getProgramDoc.sponsorDetails)?_c('v-snackbar',{attrs:{"timeout":-1,"value":true,"absolute":"","centered":"","width":"50%","top":"","color":"red accent-2","elevation":"24"}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v("mdi-trophy")]),_c('strong',[_vm._v("Awaiting Sponsorship !")])],1):_vm._e(),(!_vm.isProgramExists)?_c('div',[_c('div',{staticClass:"mt-6 d-flex align-center flex-column"},[_c('h1',[_vm._v("Program doesn't exists.")]),_c('v-btn',{staticClass:"mt-12",attrs:{"large":"","dark":"","depressed":""},on:{"click":_vm.pushHome}},[_vm._v("Click to come home")])],1)]):_c('div',[(_vm.programNameParams)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","width":"800","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-container',{staticClass:"fill-height justify-start",attrs:{"fluid":""}},[_c('div',{staticClass:"guide-bar"},[(_vm.programDoc && _vm.programDoc.data && _vm.programDoc.data._id)?_c('guide-bar',_vm._g(_vm._b({attrs:{"needsetup":_vm.needsetup,"fetch-program":_vm.fetchProgram,"operation-mode":_vm.operationMode,"title":_vm.programName,"user-type":_vm.getUserType,"is-draft":_vm.isDraft,"program-doc":_vm.programDoc,"can-publish-program":_vm.programDoc.data.published},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}},'guide-bar',attrs,false),on)):_vm._e()],1)])]}}],null,false,1515587843),model:{value:(_vm.programNameParams),callback:function ($$v) {_vm.programNameParams=$$v},expression:"programNameParams"}},[_c('v-card',{staticClass:"modal-height"},[(_vm.isLoginModalShow)?_c('login-dialog',{attrs:{"program":_vm.programNameParams},on:{"customChange":function($event){return _vm.showSignUpModal()}}}):_vm._e(),(_vm.isSignUpModalShow)?_c('sign-up',{attrs:{"program":_vm.programNameParams},on:{"signInModal":function($event){return _vm.showSignInModal()}}}):_vm._e()],1)],1)],1)],1)],1):_c('Loading',{attrs:{"process-now":true,"callback":_vm.fetchProgram},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('v-skeleton-loader',{staticClass:"guide__container mx-auto",attrs:{"loading":loading,"type":"paragraph"}},[_c('div',{staticClass:"guide-bar"},[(_vm.programDoc && _vm.programDoc.data && _vm.programDoc.data._id)?_c('guide-bar',{attrs:{"needsetup":_vm.needsetup,"fetch-program":_vm.fetchProgram,"operation-mode":_vm.operationMode,"adks-with-preview-mode":_vm.adksWithPreviewMode,"preview-enabled":_vm.previewModeEnabled,"title":_vm.programName,"is-visitor":_vm.isVisitor,"user-type":_vm.getUserType,"is-draft":_vm.isDraft,"program-doc":_vm.programDoc,"can-publish-program":_vm.programDoc.data.published,"is-save-progress":_vm.isSaveProgress},on:{"closeOverlay":_vm.handleCloseOverlay,"click":_vm.emitMolecule},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1),_c('div',{class:_vm.containerClass},[(_vm.operationMode === _vm.OperationMode.Setup)?[_c('div',{staticClass:"guide__setupbutton"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","dark":"","color":"#f79961"}},[_vm._v("Setup Mode")])],1)]:_vm._e(),(_vm.operationMode === _vm.OperationMode.Preview)?_c('div',{staticClass:"guide__setupbutton"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","dark":""},on:{"click":_vm.handleExitPreview}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-thick")]),_vm._v(" Exit Preview ")],1)],1):_vm._e(),(_vm.operationMode === _vm.OperationMode.Monitor)?_c('div',{staticClass:"guide__setupbutton"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","dark":""},on:{"click":function($event){return _vm.$emit('exit-monitor')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-thick")]),_vm._v(" Exit Monitor ")],1)],1):_vm._e(),(
              (_vm.currentPage != 0 && _vm.operationMode !== _vm.OperationMode.Preview) || _vm.windowWidth < 991
            )?_c('div',{staticClass:"guide__locks guide__locks--left unlocked",class:_vm.currentPage === 0 ? 'disabled' : '',on:{"click":function($event){_vm.windowWidth < 991 ? _vm.prevPage(_vm.currentPage) : ''}}},[_c('v-tooltip',{attrs:{"dark":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(
                    _vm.operationMode === _vm.OperationMode.Participant ||
                    _vm.operationMode === _vm.OperationMode.Monitor
                  )?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock ml-2 perform-adks-previous",attrs:{"x-large":"","color":"green","disabled":_vm.previewModeEnabled && _vm.currentPage !== 1}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.previewModeEnabled && _vm.currentPage !== 1 ? 'mdi-lock' : 'mdi-lock-open')+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Unlocked Activity")])]),_c('v-tooltip',{attrs:{"dark":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(_vm.operationMode === _vm.OperationMode.Setup)?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock ml-2 setup-adks-previous",attrs:{"x-large":"","color":"green"},on:{"click":function($event){return _vm.prevPage(_vm.currentPage)}}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Last Activity Setup")])]),_c('v-icon',{staticClass:"guide__lock",attrs:{"disabled":_vm.previewModeEnabled && _vm.currentPage !== 1,"x-large":"","color":"grey darken-3"},on:{"click":function($event){return _vm.prevPage(_vm.currentPage)}}},[_vm._v(" mdi-chevron-double-left ")])],1):_vm._e(),_c('div',{ref:"scrollable",staticClass:"guide__activities"},[(_vm.operationMode === _vm.OperationMode.Participant)?_c('div',{staticClass:"progress-bar"},[_c('Transition',{attrs:{"name":"emphasize"}},[(_vm.emphasize)?_c('v-container',{staticStyle:{"width":"835px"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"float-right mr-2",attrs:{"large":""}},[_vm._v("mdi-walk")])],1),_c('v-col',{staticStyle:{"display":"flex","align-items":"center"}},[_c('v-progress-linear',{staticClass:"white--text overline rounded-pill progress-bar-inner",attrs:{"value":_vm.progressValue,"color":"green","striped":"","height":"25"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var value = ref.value;
return [_c('strong',{staticClass:"completion-text3 font-weight-black"},[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-icon',{staticClass:"ml-2",attrs:{"large":""}},[_vm._v("mdi-flag")])],1)],1)],1):_vm._e()],1),_c('v-container',{directives:[{name:"show",rawName:"v-show",value:(!_vm.emphasize),expression:"!emphasize"}],staticStyle:{"visibility":"hidden"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("0")])],1)],1)],1):_vm._e(),_c('MModule',{attrs:{"user-type":_vm.getUserType,"student-id":_vm.studentId,"is-monitor":_vm.isMonitor,"is-visitor":_vm.isVisitor,"fetch-program":_vm.fetchProgram,"operation-mode":_vm.operationMode,"adk-type":_vm.currentAdk,"user-doc":_vm.userDoc,"is-draft":_vm.isDraft},on:{"next-page":_vm.goNext,"complete-activity":_vm.completeActivity,"isSaveProgress":function($event){_vm.isSaveProgress = true},"emphasize-completion":function($event){return _vm.animateProgress('complete')}},model:{value:(_vm.programDoc),callback:function ($$v) {_vm.programDoc=$$v},expression:"programDoc"}}),(
                (_vm.currentPage != _vm.maxIndex && _vm.operationMode !== _vm.OperationMode.Preview) ||
                _vm.windowWidth < 991
              )?_c('div',{staticClass:"guide__locks guide__locks--right",class:{
                locked: !_vm.isNextUnlocked,
                'unlocked-activity': _vm.isNextUnlocked,
                disabled: _vm.currentPage === _vm.maxIndex
              },on:{"click":function($event){_vm.windowWidth < 991 ? _vm.nextPage : ''}}},[_c('v-icon',{staticClass:"guide__lock",attrs:{"x-large":"","color":"grey darken-3","disabled":_vm.currentPage === _vm.adks && _vm.adks.length >= 1
                    ? _vm.adks.length - 1
                    : 0 || !_vm.isNextUnlocked},on:{"click":_vm.nextPage}},[_vm._v("mdi-chevron-double-right")]),_c('v-tooltip',{attrs:{"dark":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(
                      _vm.operationMode === _vm.OperationMode.Participant ||
                      _vm.operationMode === _vm.OperationMode.Monitor
                    )?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock mr-2 perform-adks-next",attrs:{"x-large":"","color":!_vm.isNextUnlocked ? 'red' : 'green'}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.isNextUnlocked ? 'mdi-lock-open' : 'mdi-lock'))]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isNextUnlocked ? 'Unlocked' : 'Locked')+" Activity")])]),_c('v-tooltip',{attrs:{"dark":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(_vm.operationMode === _vm.OperationMode.Setup)?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock mr-2 setup-adks-next",attrs:{"x-large":"","color":"#f79961"},on:{"click":_vm.nextPage}},'v-icon',attrs,false),on),[_vm._v("mdi-progress-wrench")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Setup Next Activity")])])],1):_vm._e()],1),(_vm.popUp)?_c('div',[_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.popUp),callback:function ($$v) {_vm.popUp=$$v},expression:"popUp"}},[_c('v-card',[_c('v-card-title',[_c('div',{staticClass:"headline font-weight-bold py-3"},[_vm._v("Congratulations! 🎉🌟")]),_c('div',{staticClass:"ml-auto"},[_c('v-btn',{attrs:{"large":"","icon":""},on:{"click":function($event){_vm.popUp = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-divider'),_c('v-container',{staticClass:"pa-8"},[_c('div',{staticClass:"mb-1 d-flex align-center"},[_c('p',[_vm._v("You have successfully completed the entire program.")])])])],1)],1)],1):_vm._e(),_c('Overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldDisplayOverlay || _vm.isVisitor),expression:"shouldDisplayOverlay || isVisitor"}],attrs:{"is-visitor":_vm.isVisitor},on:{"goto:last-setup":function($event){return _vm.handleChangeStep('last-setup')},"goto:last":function($event){return _vm.handleChangeStep('last')},"close":_vm.handleCloseOverlay,"goto:next":function($event){return _vm.handleChangeStep('next')},"goto:next-setup":function($event){return _vm.handleChangeStep('next-setup')}}}),_c('CheckoutOverlay',{directives:[{name:"show",rawName:"v-show",value:(
              _vm.programDoc.data.unsponsoredAdk &&
              _vm.currentAdk === _vm.programDoc.data.unsponsoredAdk &&
              _vm.getUserType === 'participant' &&
              !_vm.programDoc.data.isProgramCheckout
            ),expression:"\n              programDoc.data.unsponsoredAdk &&\n              currentAdk === programDoc.data.unsponsoredAdk &&\n              getUserType === 'participant' &&\n              !programDoc.data.isProgramCheckout\n            "}],attrs:{"fetch-program":_vm.fetchProgram}}),(
              _vm.getUserType === 'participant' &&
              !_vm.programDoc.data.isProgramCheckout &&
              _vm.programDoc.data.isProgramCheckoutBy
            )?_c('v-snackbar',{staticClass:"overlay unsponsoredbar",attrs:{"bottom":"","value":true,"timeout":"0","color":"accent","content-class":"text-center font-weight-bold white--text","light":"","width":"100vw","absolute":""}},[_vm._v(" This is an unsponsored program ")]):_vm._e()],2)])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }