var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"landing__programs",staticStyle:{"background-color":"#404142"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6","sm":"4","md":"3","lg":"2.4","xl":"2"}},[_c('v-card',{staticClass:"team-member-card title-card",style:({
          backgroundColor: '#404142',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }),attrs:{"flat":"","tile":""}},[_c('v-card-text',{staticClass:"pa-4 text-center"},[_c('h3',{staticClass:"yellow--text mb-2",staticStyle:{"font-family":"'Bungee Inline', cursive","font-size":"20px"}},[_vm._v(" Explore ")]),_c('h1',{staticClass:"title-text",staticStyle:{"color":"#ffffff"}},[_vm._v("Success Stories")])])],1)],1),_vm._l((_vm.teamMembers),function(member){return _c('v-col',{key:member.name,attrs:{"cols":"6","sm":"4","md":"3","lg":"2.4","xl":"2"}},[(member.before)?[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-card',{staticClass:"team-member-card cursor-pointer",attrs:{"flat":"","tile":""}},[_c('div',{staticClass:"before-text-container",style:({
                backgroundColor: hover ? 'white' : _vm.getMemberColor(member),
                transition: 'background-color 0.3s ease'
              })},[_c('v-card-text',{staticClass:"pa-4 quote-container"},[_c('div',{staticClass:"quote-wrapper"},[_c('v-fade-transition',[(!hover)?_c('div',{staticClass:"before-content"},[_c('p',{staticClass:"state-title",style:({ color: _vm.getLighterColor(_vm.getMemberColor(member), 0.3) })},[_vm._v(" BEFORE ")]),_c('p',{staticClass:"quote white--text before-quote",style:({ fontSize: _vm.calculateFontSize(member.before) })},[_vm._v(" \""+_vm._s(member.before)+"\" ")])]):_c('div',{staticClass:"after-content"},[_c('div',{staticClass:"quote-content"},[_c('p',{staticClass:"state-title after-title"},[_vm._v("AFTER")]),_c('p',{staticClass:"quote",style:({
                            fontSize: _vm.calculateFontSize(member.after, true),
                            color: _vm.getMemberColor(member)
                          })},[_vm._v(" \""+_vm._s(member.after)+"\" ")])]),_c('div',{staticClass:"member-info"},[(member.image)?[_c('v-avatar',{attrs:{"size":"48"}},[_c('v-img',{attrs:{"src":member.image,"alt":member.name}})],1)]:_vm._e(),_c('span',{staticClass:"member-name state-title member-title"},[_vm._v(_vm._s(_vm.getFirstName(member.name).toUpperCase()))])],2)])])],1)])],1)])]}}],null,true)})]:(member.description)?[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('v-card',{staticClass:"team-member-card cursor-pointer",attrs:{"flat":"","tile":""}},[(!hover)?_c('div',[_c('v-img',{attrs:{"src":member.image,"aspect-ratio":1,"alt":member.name}})],1):_c('div',{staticClass:"description-container",style:({
                backgroundColor: '#404142',
                transition: 'background-color 0.3s ease'
              })},[_c('v-card-text',{staticClass:"pa-4 quote-container"},[_c('div',{staticClass:"quote-wrapper"},[_c('div',{staticClass:"description-content"},[_c('div',{staticClass:"quote-content"},[_c('p',{staticClass:"quote white--text",style:({ fontSize: _vm.calculateFontSize(member.description, true) })},[_vm._v(" "+_vm._s(member.description)+" ")])]),_c('div',{staticClass:"member-info"},[(member.image)?[_c('v-avatar',{attrs:{"size":"48"}},[_c('v-img',{attrs:{"src":member.image,"alt":member.name}})],1)]:_vm._e(),_c('span',{staticClass:"member-name state-title member-title"},[_vm._v(_vm._s(_vm.getFirstName(member.name).toUpperCase()))])],2)])])])],1)])]}}],null,true)})]:(member.image)?[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var hover = ref.hover;
return [_c('v-card',{staticClass:"team-member-card cursor-pointer",attrs:{"flat":"","tile":""}},[(!hover)?_c('div',{staticClass:"image-container"},[_c('v-img',{staticClass:"dimmed-image",attrs:{"src":member.image,"aspect-ratio":1,"alt":member.name}})],1):_c('div',{staticClass:"description-container",style:({
                backgroundColor: '#404142',
                transition: 'background-color 0.3s ease'
              })},[_c('v-card-text',{staticClass:"pa-4 quote-container"},[_c('div',{staticClass:"quote-wrapper"},[_c('div',{staticClass:"description-content"},[_c('div',{staticClass:"quote-content"},[_c('p',{staticClass:"quote white--text",style:({
                          fontSize: _vm.calculateFontSize(
                            ("My name is " + (_vm.getFirstName(member.name)) + " and I'm a PilotCity Alumni"),
                            true
                          )
                        })},[_vm._v(" My name is "+_vm._s(_vm.getFirstName(member.name))+" and I'm a PilotCity Alumni ")])]),_c('div',{staticClass:"member-info"},[_c('v-avatar',{attrs:{"size":"48"}},[_c('v-img',{attrs:{"src":member.image,"alt":member.name}})],1),_c('span',{staticClass:"member-name state-title member-title"},[_vm._v(_vm._s(_vm.getFirstName(member.name).toUpperCase()))])],1)])])])],1)])]}}],null,true)})]:_vm._e()],2)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }