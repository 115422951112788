
































































































































































































import {
  computed,
  defineComponent,
  ref,
  Ref,
  watch,
  onMounted,
  onUnmounted
} from '@vue/composition-api';
import { useUserActions, useUserGetters } from '@/store';
import './styles/module.scss';
import { OperationMode } from '@/constants/operationMode';
import _ from 'lodash';
import Bar from './components/TimelineBar.vue';

export const enum ProgramActivityStatus {
  NeedSetup = 'need_setup',
  CompleteSetup = 'complete_setup',
  Standard = 'standard'
}

export interface ITimeLineItem {
  step: string;
  unlocked: boolean;
  category: 'project' | 'internship';
  tooltip: string;
  status?: ProgramActivityStatus;
}

export default defineComponent({
  name: 'Guide',
  components: {
    'guide-bar': Bar,
    Loading: () => import('@/components/Loading.vue'),
    MModule: () => import('@/components/molecules/m-module.vue'),
    Overlay: () => import('./components/Overlay.vue')
  },
  props: {
    templateId: {
      type: String,
      required: false,
      default: ''
    },
    page: {
      type: Number,
      required: false,
      default: 1
    }
  },

  setup(_props: any, ctx: any) {
    const windowWidth = ref(window.innerWidth);
    const updateWindowWidth = () => {
      const newWidth = window.innerWidth;
      windowWidth.value = newWidth;
    };
    const resizeObserver = new ResizeObserver(updateWindowWidth);
    onMounted(() => {
      updateWindowWidth(); // Initial width
      window.addEventListener('resize', updateWindowWidth);
      resizeObserver.observe(document.body);
    });
    onUnmounted(() => {
      window.removeEventListener('resize', updateWindowWidth);
      resizeObserver.disconnect();
    });
    const isTemplate = ref(true);
    const isSaveProgress = ref(false);
    const { templateId } = _props;
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const { getProgramDoc, getUserType } = useUserGetters(['getUserType', 'getProgramDoc']);
    const programDoc: Ref<{
      data: Record<string, any>; // Gives access to Document
    }> = ref({
      data: {}
    });
    const { fetchTemplateById } = useUserActions(['fetchTemplateById']);

    const userDoc = ref({
      data: user.value
    });

    const userTypeValues = ref(['Employer', 'Teacher', 'Parent', 'School']);

    async function fetchProgram() {
      if (!userTypeValues?.value?.some(i => user?.value?.userTypes.includes(i))) {
        ctx.root.$router.push({
          name: 'portfolio'
        });
      }
      await fetchTemplateById(templateId);
      programDoc.value.data = {
        adks: [],
        ...getProgramDoc.value
      };
    }

    const shouldCloseDisplayOverlay = ref(false);

    const adks = computed(() => {
      const adkValues = _.sortBy(programDoc.value?.data?.adks, 'position');
      return adkValues?.map(adk => adk.name);
    });
    const needsetup = computed(() => {
      return programDoc.value?.data?.adks?.flatMap(adk => (adk.setupRequired ? [adk.name] : []));
    });
    const maxIndex: any = computed(() => {
      return adks.value?.length - 1;
    });
    const redirectByPage = pageVal => {
      ctx.root.$router.push({
        name: 'guide',
        params: {
          templateId,
          page: pageVal
        }
      });
    };
    const currentAdk: any = computed({
      get() {
        return adks.value ? adks.value[_props.page] : '';
      },
      set(value) {
        return value;
      }
    });
    const currentPage = computed({
      get: () => _props.page,
      set: newPage => {
        if (newPage <= maxIndex.value && newPage >= 0) {
          shouldCloseDisplayOverlay.value = false;
          ctx.root.$router
            ?.replace({
              params: {
                templateId,
                page: newPage.toString()
              },
              query: { ...ctx.root.$route?.query }
            })
            .catch(() => {}); // TODO: Handle error, find a better way to redirect the page instead of using setter
        }
      }
    });
    const adksWithPreviewMode = computed(() => {
      return process.env.VUE_APP_PREVIEW_MODE_ADKS?.split(',') || [];
    });

    const goNext = () => {
      redirectByPage(currentPage.value + 1);
    };

    function emitMolecule(val) {
      currentAdk.value = val.item.name;
      currentPage.value = val.index;
    }
    const previewModeEnabled = computed(() => {
      return _props.previewView === true && getUserType.value === 'participant';
    });
    const currentUnit = computed(() => (adks.value ? adks.value[currentPage.value] : ''));
    const isInPreviewMode = computed(() => {
      return previewModeEnabled.value && adksWithPreviewMode.value.includes(currentUnit.value);
    });
    const currentUnitRequiresSetup = computed(() => {
      return currentUnit.value && needsetup.value?.includes(currentUnit.value);
    });

    const operationMode = computed(() => {
      return OperationMode.Setup;
    });
    const shouldDisplayOverlay = computed(() => {
      return (
        operationMode.value === OperationMode.Setup &&
        !currentUnitRequiresSetup.value &&
        !shouldCloseDisplayOverlay.value
      );
    });
    const isNextUnlocked = computed(() => {
      const adksData = programDoc.value?.data?.adks || [];
      const nextAdks = adksData?.slice(currentPage.value + 1);
      const filterNextAdks = nextAdks?.filter(adk => adk.status);
      const offerStatus = adksData[currentPage.value]?.fields?.find(f => f.name === 'm-offer-table')
        ?.value?.offerStatus;
      let unlocked = false;

      if (adksData.length) {
        switch (adksData[currentPage.value]?.name) {
          case 'interview':
            unlocked = true;
            break;

          case 'offer':
            unlocked = true;
            break;

          default:
            unlocked = true;
            break;
        }
        return unlocked;
      }
      return false;
    });
    const nextPage = () => {
      let position = currentPage.value + 1;
      const adksData = programDoc.value.data.adks;
      const nextAdks = adksData.slice(position);
      for (let i = 0; i < nextAdks.length; i++) {
        if (!nextAdks[i].status) {
          position += 1;
        } else {
          break;
        }
      }
      currentPage.value = position;
      // currentAdk.value = adks.value[position];
    };
    const prevPage = () => {
      let position = currentPage.value - 1;
      const adksData = programDoc.value.data.adks;
      const prevAdks: any = [];
      for (let k = position; k >= 0; k--) {
        prevAdks.push(adksData[k]);
      }

      if (currentPage.value !== 0) {
        for (let j = 0; j < prevAdks.length; j++) {
          if (!prevAdks[j].status) {
            position -= 1;
          } else {
            break;
          }
        }
      }
      currentPage.value = position;
    };

    const finishedSetup = (adkName: string) => {
      if (adkName === 'start') {
        return (
          programDoc.value?.data?.start_activity_status === ProgramActivityStatus.CompleteSetup
        );
      }
      // TODO: remove this when setup mode for Interview is implemented
      if (adkName === 'interview') {
        return true;
      }
      return (
        programDoc.value?.data?.adks?.find(adk => {
          if (adk) {
            return adk.name === adkName;
          }
          return false;
        })?.status === ProgramActivityStatus.CompleteSetup
      );
    };

    const stepsRequireSetupCompletePercentage = computed(() => {
      const totalStepsRequireSetup = needsetup.value?.length;
      let completedSteps = 0;
      // eslint-disable-next-line no-restricted-syntax
      for (const stepName of needsetup.value || []) {
        if (finishedSetup(stepName)) {
          completedSteps += 1;
        }
      }

      return Math.floor((completedSteps / totalStepsRequireSetup) * 100);
    });

    //! Doc Creation
    const containerClass = computed(() => {
      return {
        guide__page_organizer: operationMode.value === OperationMode.Setup,
        guide__page_participant: operationMode.value === OperationMode.Participant,
        guide__page_monitor: operationMode.value === OperationMode.Monitor,
        guide__preview_view: operationMode.value === OperationMode.Preview,
        'almost-done':
          stepsRequireSetupCompletePercentage.value > 51 &&
          stepsRequireSetupCompletePercentage.value < 99,
        done: stepsRequireSetupCompletePercentage.value === 100
      };
    });

    // const userDoc = ref({
    //   data: user.value
    // });
    watch(user, () => {
      userDoc.value.data = user.value;
    });

    const handleCloseOverlay = () => {
      shouldCloseDisplayOverlay.value = true;
    };

    const lastSetupPage = () => {
      for (let i = currentPage.value; i >= 0; i--) {
        const targetAdk = adks.value[i];
        if (needsetup.value.includes(targetAdk)) {
          currentPage.value = i;
          currentAdk.value = adks.value[i];
          break;
        }
      }
    };
    const nextSetupPage = () => {
      const currentPageIndex = adks.value.indexOf(currentUnit.value);

      for (let i = currentPageIndex; i < adks.value.length; i++) {
        const targetAdk = adks.value[i];

        if (needsetup.value.includes(targetAdk)) {
          currentPage.value = i;
          currentAdk.value = adks.value[i];
          break;
        }
      }
    };
    const handleChangeStep = (action: 'last-setup' | 'next-setup' | 'last' | 'next') => {
      switch (action) {
        case 'last-setup':
          lastSetupPage();
          break;
        case 'next-setup':
          nextSetupPage();
          break;
        case 'last':
          prevPage();
          break;
        case 'next':
          nextPage();
          break;
        default:
          break;
      }
    };
    const programName = computed(() => {
      return (
        programDoc.value?.data?.programName ||
        programDoc.value?.data?.templateName ||
        'Employer Name'
      );
    });
    return {
      currentUnit,
      getProgramDoc,
      goNext,
      maxIndex,
      currentPage,
      fetchProgram,
      programDoc,
      adks,
      userDoc,
      needsetup,
      adksWithPreviewMode,
      isInPreviewMode,
      previewModeEnabled,
      operationMode,
      OperationMode,
      user,
      emitMolecule,
      finishedSetup,
      stepsRequireSetupCompletePercentage,
      containerClass,
      currentAdk,
      shouldCloseDisplayOverlay,
      shouldDisplayOverlay,
      isNextUnlocked,
      nextPage,
      prevPage,
      handleCloseOverlay,
      handleChangeStep,
      programName,
      getUserType,
      // fetchUserRole,
      isSaveProgress,
      isTemplate,
      windowWidth
    };
  }
});
