
















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































// Dummy comment for deployment
import { ref, watch, computed, onMounted, reactive } from '@vue/composition-api';
import axios from 'axios';
import store, { useUserState, useUserActions, useProgramActions, useProgramState } from '@/store';
import { ActionTypes } from '@/store/modules/tools/actions';
import Profile from '@/components/Profile.vue';
import Testimonial from '@/components/landing/Testimonial.vue';

import Hero from '@/components/landing/Hero.vue';
import { PATHWAY_OPTIONS, USER_ROLES, TIME_LINES, PROGRAMS } from '@/constants/landing';
import { useVideoAsk } from '@/composables/useVideoAsk';
import { OperationMode } from '@/constants/operationMode';
import StoriesGrid from '@/views/Stories.vue';
import { fetchEmployers } from '@/services/airtable';

export default {
  name: 'Landing',
  components: {
    Profile,
    Testimonial,
    Hero,
    HeroPrograms: () => import('@/components/molecules/m-hero-programs.vue'),
    MSponsorLinkInfo: () => import('@/components/molecules/m-sponsor-link-info.vue'),
    SetupWizard: () => import('./Guide/components/SetupWizard/SetupWizard.vue'),
    MSelectTemplate: () => import('@/components/molecules/settings/m-select-template.vue'),
    StoriesGrid,
    MBasicUserInfo: () => import('@/components/molecules/m-basic-user-info-dialog.vue')
  },
  props: {
    shareCode: {
      type: String,
      required: false,
      default: ''
    },
    isEdit: {
      type: Boolean,
      required: false,
      default: false
    },
    userId: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(props, ctx) {
    const {
      getSponsorLinkByShareCode,
      fetchUserById,
      getOrganizationByLink,
      getUserByKeyAndValue
    } = useUserActions([
      'getSponsorLinkByShareCode',
      'fetchUserById',
      'getOrganizationByLink',
      'getUserByKeyAndValue'
    ]);
    const { fetchTemplates, fetchPathways } = useProgramActions([
      'fetchTemplates',
      'fetchPathways'
    ]);
    const pathwaysList = ref([]);
    const starterEmail = ref('');
    const landingTitle = ref('Explore Programs');
    const landingFirstName = ref('');
    const landingLastName = ref('');
    const landingOrgName = ref('');
    const localEdit = ref(false);
    const currentRole = ref('Student');
    const roleColor = ref('green');
    const roleLabel = ref('What employer projects would you like to explore?');
    const roles = ref(USER_ROLES);
    const { user } = useUserState(['user']);
    const currentOrigin = window.location.origin;
    const isSetupWizardOpen = ref(false);
    const selectTemplate = ref(false);
    const isFromAutoSetupValue = ref(false);
    const selectedScope = ref({});
    const isLoginPopupVisible = ref(false);
    const showSettingsDialogBox = ref(false);
    const showSaveSettings = ref(false);
    const activeTab = ref(0);
    const templateList = ref([]);
    const isSaveSelectedProgramLoading = ref(false);
    const refTemplates = [{ templateName: 'All', value: 'all' }];
    const templates = ref(refTemplates);
    const isAllDeselected = ref(false);
    const isAllDeselectedFirst = ref(false);
    const pathWaysListValue = ref([]);
    const searchResults = ref([]);
    const selectedEmployers = ref([]);
    const searchLoading = ref(false);
    const pageSize = 50;
    const hasMoreResults = ref(true);
    const offset = ref(null);
    const currentPage = ref(1);
    const searchQuery = ref('');
    const priorityOrder = [
      'All',
      'All Public Programs',
      'Your Sponsor’s Programs',
      'Build projects to win internships',
      'Build projects to pitch internships',
      'Build projects to build businesses',
      'Build projects for a better world',
      'Build skills for resilient communities',
      'Build projects for simulated internships',
      'Build projects for your portfolio',
      'Brainstorm project ideas',
      'Solve real-world problems',
      'Workshops'
    ];

    const templateIcons = {
      All: 'mdi-view-grid',
      'All Public Programs': 'mdi-view-earth',
      'Your Sponsor’s Programs': 'mdi-view-license',
      'Build projects to win internships': 'mdi-trophy-award',
      'Build projects for simulated internships': 'mdi-laptop',
      'Build projects for your portfolio': 'mdi-briefcase-account',
      'Brainstorm project ideas': 'mdi-lightbulb-on',
      'Solve real-world problems': 'mdi-fire',
      'Build projects to pitch internships': 'mdi-presentation',
      'Build projects to build businesses': 'mdi-domain',
      'Build projects for a better world': 'mdi-earth',
      'Build skills for resilient communities': 'mdi-shield-home',
      Workshops: 'mdi-hammer-wrench'
    };

    const templateColors = {
      All: 'orange',
      'All Public Programs': 'orange',
      'Your Sponsor’s Programs': 'orange',
      'Build projects to win internships': 'red',
      'Build projects to build businesses': 'green',
      'Build projects for a better world': 'yellow',
      'Build projects for simulated internships': 'blue',
      'Build projects for your portfolio': 'green',
      'Brainstorm project ideas': 'pink',
      'Solve real-world problems': 'yellow',
      'Build projects to pitch internships': 'purple',
      'Build skills for resilient communities': 'green',
      Workshops: 'orange'
    };

    const templateDurations = {
      'Build projects to win internships': '1+ Month',
      'Build projects to pitch internships': '2+ Weeks',
      'Build projects to build businesses': '1+ Month',
      'Build projects for a better world': '1+ Month',
      'Build projects for simulated internships': '2+ Week',
      'Build projects for your portfolio': '1+ Week',
      'Brainstorm project ideas': '1+ Hour',
      'Solve real-world problems': '1+ Minute',
      'Build skills for resilient communities': '1+ Week',
      Workshops: '1+ Minute'
      // Add more durations as needed
    };

    const changeRoleTo = (role: string) => {
      roles.value.student.show = true;
      roles.value.teacher.show = true;
      roles.value.school.show = true;
      roles.value.parent.show = true;
      roles.value.employer.show = true;
      roles.value.sponsor.show = true;

      switch (role) {
        case 'Student':
          roleLabel.value = 'What employer projects would you like to explore?';
          roleColor.value = roles.value.student.color;
          roles.value.student.show = false;
          break;
        case 'Teacher':
          roleLabel.value = 'What pathways do you teach in your class?';
          roleColor.value = roles.value.teacher.color;
          roles.value.teacher.show = false;
          break;
        case 'School':
          roleLabel.value = 'What employer projects would you like to explore?';
          roleColor.value = roles.value.school.color;
          roles.value.school.show = false;
          break;
        case 'Parent':
          roleLabel.value = 'What careers do you want your student to explore?';
          roleColor.value = roles.value.parent.color;
          roles.value.parent.show = false;
          break;
        case 'Employer':
          roleLabel.value = 'Check out employers in your industry';
          roleColor.value = roles.value.employer.color;
          roles.value.employer.show = false;
          break;
        case 'Sponsor':
          roleLabel.value = 'What workforce initiatives do you want to fund?';
          roleColor.value = roles.value.sponsor.color;
          roles.value.sponsor.show = false;
          break;
        default:
          break;
      }
      currentRole.value = role;
    };
    const pathwayPresets = ref(['All']);
    const handlePathwaysInput = () => {
      if (
        pathwayPresets.value.length > 1 &&
        pathwayPresets.value[pathwayPresets.value.length - 1] === 'All'
      )
        pathwayPresets.value = pathwayPresets.value.filter(filter => filter === 'All');
      else if (pathwayPresets.value.length > 1)
        pathwayPresets.value = pathwayPresets.value.filter(filter => filter !== 'All');
    };

    const updateList = newData => {
      if (newData.includeChangeTitleText) {
        landingTitle.value = newData.landingTitleText;
        landingFirstName.value = newData.landingSponsorFirstName;
        landingLastName.value = newData.landingSponsorLastName;
        landingOrgName.value = newData.landingOrganizationName;
      } else {
        landingTitle.value = 'Explore Programs';
        landingFirstName.value = newData.landingSponsorFirstName;
        landingLastName.value = newData.landingSponsorLastName;
        landingOrgName.value = newData.landingOrganizationName;
      }
    };

    const sortedTemplates = computed(() => {
      const prioritized = [];
      const remaining = [];

      templates.value.forEach(template => {
        const index = priorityOrder.indexOf(template.templateName);
        if (index !== -1) {
          prioritized[index] = template;
        } else {
          remaining.push(template);
        }
      });

      // Remove any undefined entries (in case a priority template doesn't exist)
      const filteredPrioritized = prioritized.filter(Boolean);

      // Sort remaining templates alphabetically
      remaining.sort((a, b) => a.templateName.localeCompare(b.templateName));

      const filteredData = [...filteredPrioritized, ...remaining];
      return filteredData;
    });

    const sortedForCreationTemplates = computed(() => {
      const prioritized = [];
      const remaining = [];
      const filteredTemplates = templates.value.filter(
        template =>
          template.templateName !== 'Your Sponsor’s Programs' && template.templateName !== 'All'
      );

      filteredTemplates.forEach(template => {
        const index = priorityOrder.indexOf(template.templateName);
        if (index !== -1) {
          prioritized[index] = template;
        } else {
          remaining.push(template);
        }
      });

      // Remove any undefined entries (in case a priority template doesn't exist)
      const filteredPrioritized = prioritized.filter(Boolean);

      // Sort remaining templates alphabetically
      remaining.sort((a, b) => a.templateName.localeCompare(b.templateName));

      const filteredData = [...filteredPrioritized, ...remaining];
      return filteredData;
    });

    async function loadEmployers(isLoadingMore = false) {
      if (!isLoadingMore) {
        searchResults.value = [];
        currentPage.value = 1;
        offset.value = null;
      }

      searchLoading.value = true;
      try {
        const results = await fetchEmployers({
          searchQuery: searchQuery.value,
          pageSize,
          offset: offset.value
        });

        if (results.length < pageSize) {
          hasMoreResults.value = false;
        }

        if (isLoadingMore) {
          searchResults.value = [...searchResults.value, ...results];
        } else {
          searchResults.value = results;
        }

        // Update offset for next page
        if (results.length === pageSize) {
          offset.value = results[results.length - 1].id;
        }
      } catch (error) {
        console.error('Failed to load employers:', error);
      } finally {
        searchLoading.value = false;
      }
    }

    function openAuthPopup() {
      isLoginPopupVisible.value = true;
    }

    function setActiveTab(tabName) {
      activeTab.value = tabName;
    }

    function openSettingsDialog() {
      showSettingsDialogBox.value = true;
      loadEmployers();
    }

    function clickOnSave() {
      showSaveSettings.value = true;
    }
    const settings = reactive({
      includeCreatedPrograms: false,
      includeSponsoredPrograms: true,
      includeSelfCreationOfPrograms: false,
      displayAllPublicPrograms: false,
      includeChangeTitleText: true,
      landingTitleText: 'Explore Programs',
      preselectEmployerOptions: false,
      employerDomains: [],
      insertCustomScope: false,
      customScopeText: {
        strengths: '',
        passions: '',
        problems: '',
        jobs: '',
        carrers: '',
        skills: '',
        tools: '',
        deliverables: '',
        goals: '',
        user: ''
      },
      filterProgramModels: false,
      selectedProgramModels: sortedTemplates.value.map(t => t.templateName),
      filterPathways: false,
      selectedPathways: pathwaysList.value.map(p => p.name),
      changeDefaultTab: false,
      defaultTab: 'why-pilotcity',
      toggleTabs: false,
      enabledTabs: ['why-pilotcity', 'onboard', 'join-programs'],
      landingSponsorFirstName: '',
      landingSponsorLastName: '',
      landingOrganizationName: '',
      type: 'organization',
      defaultProgramModel: 'All',
      defaultPathways: '',
      selectedSelfCreationProgram: sortedForCreationTemplates.value.map(t => t.templateName)
    });

    const initialSettings = ref(JSON.stringify(settings));

    const hasUnsavedChanges = computed(() => {
      return JSON.stringify(settings) !== initialSettings.value;
    });

    const selectAllTabs = () => {
      isAllDeselected.value = false;
      if (settings.includeSelfCreationOfPrograms) {
        settings.enabledTabs = ['why-pilotcity', 'onboard', 'join-programs', 'create-program'];
      } else {
        settings.enabledTabs = ['why-pilotcity', 'onboard', 'join-programs'];
      }
    };

    const deselectAllTabs = () => {
      isAllDeselected.value = true;
      settings.enabledTabs = ['why-pilotcity'];
    };

    const getTemplateIcon = (templateName: string) => {
      return templateIcons[templateName] || 'mdi-telegram';
    };

    const getTemplateDuration = (templateName: string) => {
      return templateDurations[templateName] || null;
    };

    const getTabColor = tabName => {
      if (tabName === 'why-pilotcity') return '#3c9dcd';
      if (tabName === 'join-programs') return '#6eba80';
      if (tabName === 'create-program') return '#ae90b0';
      if (tabName === 'onboard') return '#fec34b';
      return '#000000';
    };

    const getTabIcon = tabName => {
      if (tabName === 'why-pilotcity') return 'mdi-lightbulb-on';
      if (tabName === 'join-programs') return 'mdi-sprout';
      if (tabName === 'create-program') return 'mdi-creation';
      if (tabName === 'onboard') return 'mdi-account-plus';
      return 'mdi-lightbulb-on';
    };

    const getTabName = tabName => {
      if (tabName === 'why-pilotcity') return 'Why join?';
      if (tabName === 'join-programs') return 'Join programs';
      if (tabName === 'create-program') return 'Make a program';
      if (tabName === 'onboard') return 'Onboard now';
      return 'Why join?';
    };

    const onCheckboxChange = value => {
      console.log('Checkbox clicked for:', value);
      if (
        !settings.selectedProgramModels.includes(value) &&
        settings.defaultProgramModel === value
      ) {
        settings.defaultProgramModel = '';
      } else {
        console.log(`${value} was deselected`);
      }
    };

    const onPathwaysChange = value => {
      console.log('Checkbox clicked for:', value);
      if (!settings.selectedPathways.includes(value) && settings.defaultPathways === value) {
        settings.defaultPathways = '';
      } else {
        console.log(`${value} was deselected`);
      }
    };
    onMounted(async () => {
      // Access the route params via ctx.root.$route
      const { openLoginPopup } = ctx.root.$route.params;

      if (openLoginPopup === 'true') {
        openAuthPopup();
      }
      const issponsorCode = ctx.root.$route.params?.shareCode
        ? ctx.root.$route.params?.shareCode
        : '';
      // const payLoad = { issponsorLink: issponsorCode };
      const templateListValue: any = await fetchTemplates();
      templateList.value = templateListValue;
      templateListValue?.forEach(element => {
        templates.value.push(element);
      });
      const pathwaysData: any = await fetchPathways();
      const filterPathways = pathwaysData?.pathways;
      const pthList = filterPathways?.filter(p => p.type === 'pathways');
      pathwaysList.value = pthList?.sort((a, b) => a.abbrName.localeCompare(b.abbrName));
      // pathwaysList.value.forEach(element => {
      //   pathWaysListValue.value.push(element);
      // });
      const sponsorAllData = localStorage.getItem('sponsorAllData');
      if (sponsorAllData) {
        const getSponsorData = JSON.parse(sponsorAllData);
        settings.landingOrganizationName = getSponsorData?.name;
        settings.landingSponsorFirstName = getSponsorData?.userDetails?.firstName;
        settings.landingSponsorLastName = getSponsorData?.userDetails?.lastName;
        settings.type = getSponsorData?.type;
        if (getSponsorData?.programSettings?.includeCreatedPrograms) {
          console.log('inner');
          refTemplates.push({
            templateName: 'Your Sponsor’s Programs',
            value: 'sponsored_programs'
          });
        }
      }
      // if (
      //   localStorage.getItem('sponsorAllData') &&
      //   JSON.parse(localStorage.getItem('sponsorAllData'))?.programSettings
      // ) {
      //   console.log('4202', JSON.parse(localStorage.getItem('sponsorAllData')).programSettings);
      //   Object.assign(settings, JSON.parse(localStorage.getItem('sponsorAllData')).programSettings);
      // } else {
      //   console.log('4205', settings);
      //   Object.assign(settings, settings);
      // }
      initialSettings.value = JSON.stringify(settings);
    });

    // watch(newpass => {
    //   window.videoask.loadEmbed({
    //     kind: 'widget',
    //     url: 'https://www.videoask.com/f9u4t8kem',
    //     options: {
    //       widgetType: 'VideoThumbnailWindowTall',
    //       text: 'Watch PilotCity TV',
    //       backgroundColor: '#000000',
    //       position: 'bottom-right'
    //     }
    //   });
    // });

    const operationMode = computed(() => {
      return OperationMode.Landing;
    });

    function openCreateProgram(val) {
      selectTemplate.value = true;
      isFromAutoSetupValue.value = true;
      selectedScope.value = val;
    }

    const userType = computed(() => {
      let userType: 'organizer' | 'stakeholder' | 'participant' | '' = 'stakeholder';

      if (ctx.root.$route.query && ctx.root.$route.query.program) {
        userType = '';
      }
      return userType;
    });
    useVideoAsk(user, operationMode, userType);

    const demoDialog = ref(false);
    const studentsDialog = ref(false);
    const teachersDialog = ref(false);
    const schoolsDialog = ref(false);
    const parentsDialog = ref(false);
    const employersDialog = ref(false);
    const sponsorsDialog = ref(false);
    const sponsorDialog = ref(false);

    const isOpenPopup = ref(false);
    const sponsorData = ref(null);
    const getSponsorInfo = async shareCode => {
      const sponsorLink = await getSponsorLinkByShareCode({ share_code: shareCode });
      const keysValue = Object.keys(sponsorLink?.claimedBy);
      const sponsorUserId = '';
      const claimTimes = keysValue?.filter(
        key => key?.toString() === user?.value?._id?.toString()
      )?.length;
      const ENDPOINT =
        process.env.VUE_APP_NODE_ENV === 'production'
          ? 'https://pilotcity.com'
          : 'https://stage.pilotcity.com';

      localStorage.setItem('programSettings', JSON.stringify(sponsorLink.programSettings));
      if (sponsorLink) {
        const sponsorInfo: any = {};
        let organizationDetails: any;
        if (sponsorLink?.type === 'personal') {
          const userInfo = await getUserByKeyAndValue({
            key: '_id',
            value: decodeURIComponent(sponsorLink?.createdBy)
          });
          // const userInfo = await fetchUserById({ user_id: sponsorLink?.createdBy });
          sponsorInfo.userDetails = userInfo?.data;
          sponsorInfo.type = 'user';
          sponsorInfo.sponsorUserId = decodeURIComponent(sponsorLink?.createdBy);
          if (
            user?.value?._id.toString() === decodeURIComponent(sponsorLink?.createdBy) &&
            props?.isEdit
          ) {
            localEdit.value = true;
          } else {
            localEdit.value = false;
          }
        } else if (sponsorLink?.type === 'organization') {
          const userInfoOrg = await getUserByKeyAndValue({
            key: '_id',
            value: sponsorLink?.userId
          });
          sponsorInfo.userDetails = userInfoOrg?.data;
          const link = `${ENDPOINT}/join/${sponsorLink?.createdBy}`;
          organizationDetails = await getOrganizationByLink(link);
          sponsorInfo.type = 'organization';
          sponsorInfo.sponsorUserId = sponsorLink?.userId;
          sponsorInfo.name = organizationDetails?.name;
          if (user?.value?._id.toString() === sponsorLink?.userId.toString() && props?.isEdit) {
            localEdit.value = true;
          } else {
            localEdit.value = false;
          }
        }
        sponsorInfo.programSettings = sponsorLink?.programSettings;
        sponsorInfo.original_id = sponsorLink?._id;
        localStorage.setItem('sponsorAllData', JSON.stringify(sponsorInfo));
        sponsorInfo.sponsorLink = `${ENDPOINT}/sponsor/${sponsorLink?.shareCode}`;
        sponsorInfo.shareCode = sponsorLink?.shareCode;
        // if (sponsorInfo) {
        //   if (sponsorLink?.creationType === 'auto') {
        //     const wallet = await getWalletByOwnerId({ owner_id: sponsorLink?.createdBy });
        //     if (wallet) {
        //       sponsorInfo.balance = wallet?.balance;
        //     }
        //   } else {
        //     sponsorInfo.balance = sponsorLink?.index || 0;
        //   }
        // }
        if (
          sponsorLink?.programSettings?.landingTitleText &&
          sponsorLink?.programSettings?.includeChangeTitleText
        ) {
          landingTitle.value = sponsorLink?.programSettings?.landingTitleText;
          landingFirstName.value = sponsorLink?.programSettings?.landingSponsorFirstName;
          landingLastName.value = sponsorLink?.programSettings?.landingSponsorLastName;
          landingOrgName.value = sponsorLink?.programSettings?.landingOrganizationName;
          settings.landingSponsorFirstName = sponsorInfo?.userDetails?.firstName;
          settings.landingSponsorLastName = sponsorInfo?.userDetails?.lastName;
          settings.landingOrganizationName = organizationDetails?.name
            ? organizationDetails?.name
            : '';
        } else {
          landingTitle.value = 'Explore Programs';
          landingFirstName.value = sponsorInfo?.userDetails?.firstName;
          landingLastName.value = sponsorInfo?.userDetails?.lastName;
          landingOrgName.value = organizationDetails?.name ? organizationDetails?.name : '';
          settings.landingSponsorFirstName = sponsorInfo?.userDetails?.firstName;
          settings.landingSponsorLastName = sponsorInfo?.userDetails?.lastName;
          settings.landingOrganizationName = organizationDetails?.name
            ? organizationDetails?.name
            : '';
        }
        sponsorData.value = sponsorInfo;
        isOpenPopup.value = true;
      }
      if (sponsorLink?.programSettings) {
        Object.assign(settings, sponsorLink?.programSettings);
      } else {
        Object.assign(settings, settings);
      }
      if (settings.includeCreatedPrograms) {
        const index = templates.value.findIndex(
          template => template.templateName === 'Your Sponsor’s Programs'
        );
        console.log(index);

        if (index === -1) {
          templates.value.push({
            templateName: 'Your Sponsor’s Programs',
            value: 'sponsored_programs'
          });
        }
      }
      if (settings.filterProgramModels && settings.selectedProgramModels) {
        templateList.value = templates.value.filter(template =>
          settings.selectedProgramModels.includes(template.templateName)
        );
      }
      if (!settings.filterProgramModels) {
        templateList.value.forEach(item => {
          if (!settings.selectedProgramModels.includes(item.templateName)) {
            settings.selectedProgramModels.push(item.templateName);
          }
        });
      }
      if (settings.filterPathways && settings.selectedPathways && props.shareCode) {
        pathWaysListValue.value = pathwaysList.value.filter(pathway =>
          settings.selectedPathways.includes(pathway.name)
        );
      }
      if (!settings.filterPathways && props.shareCode) {
        pathWaysListValue.value.forEach(item => {
          if (!settings.selectedPathways.includes(item.name)) {
            settings.selectedPathways.push(item.name);
          }
        });
      }
      if (settings.changeDefaultTab && settings.defaultTab) {
        const defaultTabIndex = settings.enabledTabs.indexOf(settings.defaultTab);
        if (defaultTabIndex > -1) {
          activeTab.value = defaultTabIndex;
        }
      }
      initialSettings.value = JSON.stringify(settings);
      if (!settings.includeSelfCreationOfPrograms) {
        templateList.value.forEach(item => {
          if (!settings.selectedSelfCreationProgram.includes(item.templateName)) {
            settings.selectedSelfCreationProgram.push(item.templateName);
          }
        });
      }
      if (
        settings.includeSelfCreationOfPrograms &&
        settings?.selectedSelfCreationProgram.length === 0
      ) {
        templateList.value.forEach(item => {
          if (!settings.selectedSelfCreationProgram.includes(item.templateName)) {
            settings.selectedSelfCreationProgram.push(item.templateName);
          }
        });
      }

      if (localStorage.getItem('sponsorShipLinkPath')) {
        localStorage.removeItem('sponsorShipLinkPath');
      }
    };

    function saveSettings() {
      return new Promise((resolve, reject) => {
        isSaveSelectedProgramLoading.value = true;
        if (!settings.includeChangeTitleText) {
          const sponsorAllData = localStorage.getItem('sponsorAllData');
          if (sponsorAllData) {
            const getSponsorData = JSON.parse(sponsorAllData);
            settings.landingOrganizationName = getSponsorData?.name;
            settings.landingSponsorFirstName = getSponsorData?.userDetails?.firstName;
            settings.landingSponsorLastName = getSponsorData?.userDetails?.lastName;
          }
        }
        const API_ENDPOINT = `${process.env.VUE_APP_SPONSOR_SERVICE}/save-sponsor-setting`;
        const data = {
          shareCode: props.shareCode,
          programSettings: settings
        };
        axios
          .post(`${API_ENDPOINT}`, data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          })
          .then(async res => {
            isSaveSelectedProgramLoading.value = false;
            const sponsorAllData = JSON.parse(localStorage.getItem('sponsorAllData'));
            sponsorAllData.programSettings = settings;
            localStorage.setItem('sponsorAllData', JSON.stringify(sponsorAllData));
            localStorage.setItem('programSettings', JSON.stringify(settings));
            ctx.emit('updateList', settings);
            resolve(res);
          })
          .catch(error => {
            isSaveSelectedProgramLoading.value = false;
            console.log(error);
            reject(error);
          });
      });
    }
    const saveSettingsAndPrograms = async () => {
      try {
        // Save settings
        // localStorage.setItem('programSettings', JSON.stringify(settings));
        // update settings
        if (settings.enabledTabs.length === 0) {
          isAllDeselected.value = true;
        } else if (
          (settings.changeDefaultTab && settings.defaultTab === '') ||
          (settings.selectedPathways.length === 0 && settings.filterPathways) ||
          (settings.selectedProgramModels.length === 0 && settings.filterProgramModels)
        ) {
          isAllDeselectedFirst.value = true;
        } else {
          await saveSettings();
          window.location.reload();

          // Save selected programs
          // await saveSelectedProgram();

          // Update initial settings to reset change detection
          initialSettings.value = JSON.stringify(settings);

          // Close the dialog
          showSettingsDialogBox.value = false;
        }
      } catch (error) {
        console.error('Error saving settings and programs:', error);
      }
    };

    const onClose = () => {
      showSettingsDialogBox.value = false;
      isAllDeselected.value = false;
      isAllDeselectedFirst.value = false;
      Object.assign(settings, JSON.parse(initialSettings.value));
    };
    // Add watcher to handle self-creation program tab
    watch(
      () => settings.includeSelfCreationOfPrograms,
      newValue => {
        if (newValue && !settings.enabledTabs.includes('create-program')) {
          settings.enabledTabs.push('create-program');
        } else if (!newValue) {
          settings.enabledTabs = settings.enabledTabs.filter(tab => tab !== 'create-program');
        }
      }
    );

    // Update the watcher to only initialize if the array is empty
    watch(
      () => settings.filterProgramModels,
      newValue => {
        if (newValue && settings.selectedProgramModels.length === 0) {
          // Only initialize if no templates are selected
          settings.selectedProgramModels = sortedTemplates.value.map(t => t.templateName);
        }
      }
    );

    watch(
      () => pathwaysList.value,
      newValue => {
        if (Array.isArray(newValue)) {
          newValue.forEach(elementPath => {
            // Check if the element already exists in pathWaysListValue
            const isDuplicate = pathWaysListValue.value.some(
              item => JSON.stringify(item) === JSON.stringify(elementPath)
            );
            // Only add if not a duplicate
            if (!isDuplicate) {
              pathWaysListValue.value.push(elementPath);
            }
          });
          if (settings.filterPathways && settings.selectedPathways && props.shareCode) {
            pathWaysListValue.value = pathwaysList.value.filter(pathway =>
              settings.selectedPathways.includes(pathway.name)
            );
          }
          if (!settings.filterPathways && props.shareCode) {
            pathWaysListValue.value.forEach(item => {
              if (!settings.selectedPathways.includes(item.name)) {
                settings.selectedPathways.push(item.name);
              }
            });
          }
        }
      }
    );

    const selectAllProgramModels = () => {
      settings.selectedProgramModels = sortedTemplates.value.map(t => t.templateName);
    };

    const deselectAllProgramModels = () => {
      settings.selectedProgramModels = [];
    };

    const selectAllCreationProgram = () => {
      settings.selectedSelfCreationProgram = sortedTemplates.value.map(t => t.templateName);
    };

    const deselectAllCreationProgram = () => {
      settings.selectedSelfCreationProgram = [];
    };

    const getTemplateColor = (templateName: string) => {
      return templateColors[templateName] || 'grey darken-3';
    };

    const selectOnlyProgramModel = (templateName: string) => {
      settings.selectedProgramModels = [templateName];
    };

    const selectAllPathways = () => {
      settings.selectedPathways = pathwaysList.value.map(p => p.name);
    };

    const deselectAllPathways = () => {
      settings.selectedPathways = [];
    };

    const selectOnlyPathway = (pathwayName: string) => {
      settings.selectedPathways = [pathwayName];
    };

    const displayProgramCreated = value => {
      console.log('Here', value);
      if (value) {
        const index = templates.value.findIndex(
          template => template.templateName === 'Your Sponsor’s Programs'
        );
        console.log(index);

        if (index === -1) {
          templates.value.push({
            templateName: 'Your Sponsor’s Programs',
            value: 'sponsored_programs'
          });
          const indexSettings = settings.selectedProgramModels.findIndex(
            program => program === 'Your Sponsor’s Programs'
          );
          if (indexSettings === -1) {
            settings.selectedProgramModels.push('Your Sponsor’s Programs');
          }
        }
      } else {
        // Remove the object if it exists
        templates.value = templates.value.filter(
          template => template.templateName !== 'Your Sponsor’s Programs'
        );
        settings.selectedProgramModels = settings.selectedProgramModels.filter(
          program => program !== 'Your Sponsor’s Programs'
        );
      }
    };

    const toggleSelfCreationOfPrograms = (name, value) => {
      settings.includeSelfCreationOfPrograms = value.includes('create-program');
      if (!value.includes('create-program')) {
        settings.defaultTab = settings.defaultTab === 'create-program' ? '' : settings.defaultTab;
        settings.includeSelfCreationOfPrograms = false;
        settings.insertCustomScope = false;
        settings.preselectEmployerOptions = false;
      } else if (!value.includes('why-pilotcity') && settings.defaultTab === 'why-pilotcity') {
        settings.defaultTab = '';
      } else if (!value.includes('join-programs') && settings.defaultTab === 'join-programs') {
        settings.defaultTab = '';
      } else if (!value.includes('onboard') && settings.defaultTab === 'onboard') {
        settings.defaultTab = '';
      } else {
        console.log(settings.defaultTab);
      }
    };

    if (props.shareCode) {
      getSponsorInfo(props.shareCode);
    }
    if (
      localStorage?.getItem(`sponsorInfo`) &&
      props?.shareCode !== JSON.parse(localStorage?.getItem(`sponsorInfo`))?.shareCode
    ) {
      localStorage?.removeItem(`sponsorInfo`);
      localStorage?.removeItem(`sponsorAllData`);
      store.dispatch(`tools/${ActionTypes.disableBalloonPopup}`);
      getSponsorInfo(props.shareCode);
    }
    const phoneDialog = ref(false);
    const people = [
      {
        name: 'Jerold Inocencio',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JeroldInocencio.png'
      },
      {
        name: 'Parul Veda',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ParulVeda.png'
      },
      {
        name: 'Noah MacLean',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NoahMacLean.png'
      },
      {
        name: 'Riddhi Bhandari',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/RiddhiBhandari.png'
      },
      {
        name: 'Timothy Deloney',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/TimDeloney.png'
      },
      {
        name: 'Prajit Saravanan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/PrajitSaravanan.png'
      },
      {
        name: 'Jason Yang',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/JasonYang.png'
      },
      {
        name: 'Nick Falk',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/NickFalk.png'
      },
      {
        name: 'Samantha Wilcox',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/SamanthaWilcox.png'
      },
      {
        name: 'Ayan Shah',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/AyanShah.png'
      },
      {
        name: 'Devarsh Jayakrishnan',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/DevarshJayakrishnan.png'
      },
      {
        name: 'Erik Pedersen',
        image: 'https://pilotcity-headshots.s3.us-west-1.amazonaws.com/ErikPedersen.png'
      }
    ];

    return {
      errors: [],
      pathwaySearch: null,
      pathwayOptions: PATHWAY_OPTIONS,
      pathwayPresets,
      handlePathwaysInput,
      user,
      starterEmail,
      currentRole,
      isLoginPopupVisible,
      changeRoleTo,
      roleLabel,
      roleColor,
      roles,
      snackbar: true,
      timeLines: TIME_LINES,
      programs: PROGRAMS,
      currentOrigin,
      demoDialog,
      studentsDialog,
      teachersDialog,
      schoolsDialog,
      employersDialog,
      parentsDialog,
      sponsorsDialog,
      sponsorDialog,
      isOpenPopup,
      sponsorData,
      isSetupWizardOpen,
      selectTemplate,
      openCreateProgram,
      selectedScope,
      isFromAutoSetupValue,
      phoneDialog,
      people,
      localEdit,
      landingTitle,
      landingFirstName,
      openAuthPopup,
      landingLastName,
      landingOrgName,
      updateList,
      showSettingsDialogBox,
      openSettingsDialog,
      activeTab,
      setActiveTab,
      showSaveSettings,
      clickOnSave,
      templateList,
      pathwaysList,
      selectAllTabs,
      deselectAllTabs,
      hasUnsavedChanges,
      settings,
      isSaveSelectedProgramLoading,
      selectAllProgramModels,
      deselectAllProgramModels,
      selectOnlyProgramModel,
      selectAllPathways,
      deselectAllPathways,
      selectOnlyPathway,
      sortedTemplates,
      templates,
      saveSettingsAndPrograms,
      getTemplateColor,
      getTemplateIcon,
      getTemplateDuration,
      onClose,
      isAllDeselected,
      toggleSelfCreationOfPrograms,
      getTabColor,
      getTabIcon,
      getTabName,
      isAllDeselectedFirst,
      pathWaysListValue,
      searchLoading,
      searchResults,
      onCheckboxChange,
      onPathwaysChange,
      displayProgramCreated,
      selectAllCreationProgram,
      deselectAllCreationProgram,
      sortedForCreationTemplates
    };
  },
  computed: {
    isShareCodeLink() {
      return !!this.shareCode;
    }
  }
};
