






import { useUserActions, useUserState } from '@/store';
import { watchEffect } from '@vue/composition-api';
import { ObjectId } from 'bson';

export default {
  setup(_props, ctx: any) {
    // const { collection } = useDbGetters(['collection']);
    const { getLatestDocumentByUserId } = useUserActions(['getLatestDocumentByUserId']);
    const { user }: any = useUserState(['user']);
    async function redirect() {
      // collection.value!('Program')
      //   .findOne({ organizers: new ObjectId(user.value._id) }, { sort: { dateCreated: -1 } })
      //   .then(res => {
      const res = await getLatestDocumentByUserId({
        document_type: 'Program',
        user_id: user.value._id.toString()
      });
      if (res) {
        ctx.root.$router.push({ path: `/monitor?program=${res.document._id.toString()}` });
      } else {
        ctx.root.$router.push({ name: 'portfolio' });
      }
      // });
    }

    watchEffect(async () => {
      if (user.value?._id) {
        await redirect();
      }
    });

    return { redirect };
  }
};
