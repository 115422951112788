const timeZoneList = [
  'Etc/GMT+12',
  'Pacific/Midway',
  'Pacific/Honolulu',
  'US/Alaska',
  'America/Los_Angeles',
  'America/Tijuana',
  'US/Arizona',
  'America/Chihuahua',
  'US/Mountain',
  'America/Managua',
  'US/Central',
  'America/Mexico_City',
  'Canada/Saskatchewan',
  'America/Bogota',
  'US/Eastern',
  'US/East-Indiana',
  'Canada/Atlantic',
  'America/Caracas',
  'America/Manaus',
  'America/Santiago',
  'Canada/Newfoundland',
  'America/Sao_Paulo',
  'America/Argentina/Buenos_Aires',
  'America/Godthab',
  'America/Montevideo',
  'America/Noronha',
  'Atlantic/Cape_Verde',
  'Atlantic/Azores',
  'Africa/Casablanca',
  'Etc/Greenwich',
  'Europe/Amsterdam',
  'Europe/Belgrade',
  'Europe/Brussels',
  'Europe/Sarajevo',
  'Africa/Lagos',
  'Asia/Amman',
  'Europe/Athens',
  'Asia/Beirut',
  'Africa/Cairo',
  'Africa/Harare',
  'Europe/Helsinki',
  'Asia/Jerusalem',
  'Europe/Minsk',
  'Africa/Windhoek',
  'Asia/Kuwait',
  'Europe/Moscow',
  'Africa/Nairobi',
  'Asia/Tbilisi',
  'Asia/Tehran',
  'Asia/Muscat',
  'Asia/Baku',
  'Asia/Yerevan',
  'Asia/Kabul',
  'Asia/Yekaterinburg',
  'Asia/Karachi',
  'Asia/Calcutta',
  'Asia/Calcutta',
  'Asia/Katmandu',
  'Asia/Almaty',
  'Asia/Dhaka',
  'Asia/Rangoon',
  'Asia/Bangkok',
  'Asia/Krasnoyarsk',
  'Asia/Hong_Kong',
  'Asia/Kuala_Lumpur',
  'Asia/Irkutsk',
  'Australia/Perth',
  'Asia/Taipei',
  'Asia/Tokyo',
  'Asia/Seoul',
  'Asia/Yakutsk',
  'Australia/Adelaide',
  'Australia/Darwin',
  'Australia/Brisbane',
  'Australia/Canberra',
  'Australia/Hobart',
  'Pacific/Guam',
  'Asia/Vladivostok',
  'Asia/Magadan',
  'Pacific/Auckland',
  'Pacific/Fiji',
  'Pacific/Tongatapu'
];

const TABLE_HEADER = [
  { text: 'Info', value: 'directoryPreference', sortable: false, align: 'start', width: '5%' },
  {
    text: 'Name',
    align: 'start',
    sortable: false,
    value: 'first_name',
    width: '20%'
  },
  { text: 'Email', value: 'email', sortable: false, align: 'start', width: '40%' },
  { text: 'Phone', value: 'phone_number', sortable: false, align: 'start', width: '30%' },
  { text: 'Role', value: 'role', sortable: false, align: 'start', width: '5%' }
];

const TIMESHEET_HEADER = [
  { text: 'Type', value: 'timesheetFor', sortable: false, align: 'start', width: '10%' },
  {
    text: 'Timestamp',
    align: 'start',
    sortable: false,
    value: 'timesheetFilledAt',
    width: '50%'
  },

  // { text: 'Time', value: 'timesheetFilledAt', sortable: false, align: 'start' },

  { text: 'Todo/Toda', value: 'transcription', sortable: false, align: 'start', width: '30%' },
  { text: 'Receipt', value: 'shareUrl', sortable: false, align: 'center', width: '3%' },
  { text: '', value: 'interactionId', sortable: false, align: 'center', width: '3%' }
];

const MAKE_ADK_TABLE_HEADER = [
  { text: '', align: 'center', value: 'avatar', width: '5%' },
  { text: 'Log Description', align: 'start', value: 'log', width: '50%' },
  { text: 'Proof', align: 'start', value: 'proof', sortable: false, width: '5%' },
  { text: 'Timestamp', align: 'start', value: 'time', width: '40%' },
  { text: 'Delete', align: 'center', value: 'delete', sortable: false, width: '5%' }
];

const group = ['Setup', 'Project', 'Screening', 'Internship'];
const required = ['Creator requires this activity', 'Yes', 'No'];
const lockOrder = ['Creator locked activity group and placement order', 'Yes', 'No'];
const deliverable = ['Yes', 'No'];
const notifications = ['Creator turned on by default', 'Turn on', 'Turn off'];
const accessibility = [
  'Creator has turned off accessibility anytime',
  'Creator has turned on accessibility anytime',
  'Yes',
  'No'
];
const endEarly = [
  'Creator has not allowed participants to end early after this activity',
  'Creator has allow end early option only at preset order placement',
  'Yes',
  'No'
];
const maxMinutes = [2, 3, 5, 7, 10];
const teamSetting1 = [
  'Everyone can view, owners must approve for edit (Recommended)',
  'Everyone can view & edit ',
  'Everyone can only view & edit own'
];
const teamSetting2 = ['test 1', 'test 2', 'test 3'];
const teamSetting3 = ['test 1', 'test 2', 'test 3'];

const planSetting1 = [
  'Automatically pre-populate pre-internship data (Recommended)',
  'No, have students start fresh'
];
const planSetting2 = ['plan1', 'plan2', 'plan3'];
const planSetting3 = ['plan1', 'plan2', 'plan3'];

const interviewSetting1 = ['0', '1', '3', '5 (Recommended)', '7', '10', '15', '20', '25', '30'];
const interviewSetting2 = ['int1', 'int2', 'int3'];
const interviewSetting3 = ['int1', 'int2', 'int3'];

const implementationSetting1 = ['0', '1', '2', '3 (Recommended)', '5', '7', '10'];
const implementationSetting2 = ['1', '2', '3'];
const implementationSetting3 = ['4', '5', '6'];

const debriefSetting1 = ['d1', 'd2', 'd3'];
const debriefSetting2 = ['d1', 'd2', 'd3'];
const debriefSetting3 = ['d1', 'd2', 'd3'];

const storySetting1 = [
  'Written (Articles, Blog, etc)',
  'Video (Shorts, Tour, Demo, etc)',
  'Graphic (Infographics, social post, etc)'
];
const storySetting2 = ['s1', 's2', 's3'];
const storySetting3 = ['s1', 's2', 's3'];

const taskSetting1 = [
  'High-Low Priority, then Easy - Hard Difficulty (default)',
  'High-Low Priority, then Hard - Easy Difficulty',
  'Custom (Unsorted, based on entry with drag & drop)'
];
const taskSetting2 = ['t1', 't2', 't3'];
const taskSetting3 = ['t1', 't2', 't3'];

const presentationSetting1 = ['5', '10', '15 (Default)', '20', '30', '45', '60', '90', '120'];
const presentationSetting2 = ['p1', 'p2', 'p3'];
const presentationSetting3 = ['p1', 'p2', 'p3'];

const tinkerTableHeader = [
  { text: '', align: 'center', value: 'avatar', width: '5%' },
  { text: 'Logged Minutes', align: 'start', value: 'minutes', width: '30%' },
  { text: 'Time', align: 'start', value: 'timestamp', width: '35%' },
  { text: 'Team Member', align: 'start', value: 'name', width: '40%' }
  // { text: 'Delete', align: 'center', value: 'delete', sortable: false, width: '5%' }
];

const VERSION_TABLE_HEADER = [
  { text: 'Version', value: 'version', sortable: false, align: 'start', width: '5%' },
  {
    text: 'Version Changes',
    align: 'left',
    sortable: false,
    value: 'version_changes',
    width: '40%'
  },
  { text: 'Version Created At', value: 'created', sortable: false, align: 'start', width: '20%' },
  { text: 'Action', value: 'action', sortable: false, align: 'start', width: '5%' }
];

const LANDING_PG_CAT = [
  {
    text: 'Agriculture',
    icon: 'mdi-tractor-variant',
    color: 'green'
  },
  {
    text: 'Arts',
    icon: 'mdi-palette',
    color: 'red'
  },
  {
    text: 'Construction',
    icon: 'mdi-account-hard-hat',
    color: 'orange'
  },
  {
    text: 'Business',
    icon: 'mdi-handshake',
    color: 'pink'
  },
  {
    text: 'Education',
    icon: 'mdi-school',
    color: 'blue'
  },
  {
    text: 'Energy',
    icon: 'mdi-wind-power',
    color: 'green'
  },
  {
    text: 'Engineering',
    icon: 'mdi-integrated-circuit-chip',
    color: 'purple'
  },
  {
    text: 'Fashion',
    icon: 'mdi-pencil-ruler',
    color: 'yellow'
  },
  {
    text: 'Health',
    icon: 'mdi-medical-bag',
    color: 'pink'
  },
  {
    text: 'Hospitality',
    icon: 'mdi-chef-hat',
    color: 'red'
  },
  {
    text: 'Information',
    icon: 'mdi-database-arrow-up',
    color: 'blue'
  },
  {
    text: 'Manufacturing',
    icon: 'mdi-cogs',
    color: 'orange'
  },
  {
    text: 'Marketing',
    icon: 'mdi-face-agent',
    color: 'yellow'
  },
  {
    text: 'Public Service',
    icon: 'mdi-town-hall',
    color: 'purple'
  },
  {
    text: 'Transportation',
    icon: 'mdi-train-car',
    color: 'green'
  }
];

const COMPONENTS_JSON = [
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'AVSelect'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Text'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Divider'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'RadioGroup'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'CheckBox'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Date'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Generate Interview'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Contact Adk'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Textarea'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Range Slider'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Welcome adk'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Meet Adk'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Header'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'ComboBox'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Research'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Discord Workspace'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Train'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Discord Community'
  },
  {
    draggable_icon: 'https://cdn-icons-png.flaticon.com/512/2476/2476980.png',
    draggable_name: 'Matterport'
  }
];

export {
  timeZoneList,
  LANDING_PG_CAT,
  TABLE_HEADER,
  MAKE_ADK_TABLE_HEADER,
  group,
  required,
  lockOrder,
  deliverable,
  notifications,
  accessibility,
  endEarly,
  maxMinutes,
  teamSetting1,
  teamSetting2,
  teamSetting3,
  planSetting1,
  planSetting2,
  planSetting3,
  interviewSetting1,
  interviewSetting2,
  interviewSetting3,
  implementationSetting1,
  implementationSetting2,
  implementationSetting3,
  debriefSetting1,
  debriefSetting2,
  debriefSetting3,
  storySetting1,
  storySetting2,
  storySetting3,
  taskSetting1,
  taskSetting2,
  taskSetting3,
  presentationSetting1,
  presentationSetting2,
  presentationSetting3,
  TIMESHEET_HEADER,
  tinkerTableHeader,
  VERSION_TABLE_HEADER,
  COMPONENTS_JSON
};
