var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Loading',{attrs:{"process-now":true,"callback":_vm.fetchProgram},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var loading = ref.loading;
return [_c('v-skeleton-loader',{staticClass:"guide__container mx-auto",attrs:{"loading":loading,"type":"paragraph"}},[_c('div',{staticClass:"guide-bar"},[(_vm.programDoc && _vm.programDoc.data && _vm.programDoc.data._id)?_c('guide-bar',{attrs:{"needsetup":_vm.needsetup,"fetch-program":_vm.fetchProgram,"operation-mode":_vm.operationMode,"adks-with-preview-mode":_vm.adksWithPreviewMode,"preview-enabled":_vm.previewModeEnabled,"title":_vm.programName,"user-type":"organizer","program-doc":_vm.programDoc,"can-publish-program":_vm.programDoc.data.published,"is-save-progress":_vm.isSaveProgress,"is-template":_vm.isTemplate},on:{"closeOverlay":_vm.handleCloseOverlay,"click":_vm.emitMolecule},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1),_c('div',{class:_vm.containerClass},[(_vm.programDoc.data && _vm.programDoc.data.adks && _vm.programDoc.data.adks.length >= 1)?_c('div',[(_vm.operationMode === _vm.OperationMode.Setup)?[_c('div',{staticClass:"guide__setupbutton"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","dark":"","color":"#f79961"}},[_vm._v("Setup Mode")])],1)]:_vm._e(),(_vm.operationMode === _vm.OperationMode.Preview)?_c('div',{staticClass:"guide__setupbutton"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","dark":""},on:{"click":_vm.handleExitPreview}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-thick")]),_vm._v(" Exit Preview ")],1)],1):_vm._e(),(_vm.operationMode === _vm.OperationMode.Monitor)?_c('div',{staticClass:"guide__setupbutton"},[_c('v-btn',{staticClass:"font-weight-bold",attrs:{"small":"","depressed":"","dark":""},on:{"click":function($event){return _vm.$emit('exit-monitor')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close-thick")]),_vm._v(" Exit Monitor ")],1)],1):_vm._e(),(
              (_vm.currentPage != 0 && _vm.operationMode !== _vm.OperationMode.Preview) || _vm.windowWidth < 991
            )?_c('div',{staticClass:"guide__locks guide__locks--left unlocked",class:_vm.currentPage === 0 ? 'disabled' : '',on:{"click":function($event){_vm.windowWidth < 991 ? _vm.prevPage : ''}}},[_c('v-tooltip',{attrs:{"dark":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [(
                    _vm.operationMode === _vm.OperationMode.Participant ||
                    _vm.operationMode === _vm.OperationMode.Monitor
                  )?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock ml-2 perform-adks-previous",attrs:{"x-large":"","color":"green","disabled":_vm.previewModeEnabled && _vm.currentPage !== 1}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.previewModeEnabled && _vm.currentPage !== 1 ? 'mdi-lock' : 'mdi-lock-open')+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Unlocked Activity")])]),_c('v-tooltip',{attrs:{"dark":"","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(_vm.operationMode === _vm.OperationMode.Setup)?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock ml-2 setup-adks-previous",attrs:{"x-large":"","color":"green"},on:{"click":_vm.prevPage}},'v-icon',attrs,false),on),[_vm._v("mdi-check-circle")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Last Activity Setup")])]),_c('v-icon',{staticClass:"guide__lock",attrs:{"disabled":_vm.previewModeEnabled && _vm.currentPage !== 1,"x-large":"","color":"grey darken-3"},on:{"click":_vm.prevPage}},[_vm._v(" mdi-chevron-double-left ")])],1):_vm._e(),_c('div',{staticClass:"guide__activities"},[_c('MModule',{style:(_vm.isTemplate ? 'pointer-events: none' : ''),attrs:{"user-type":"organizer","is-template":_vm.isTemplate,"fetch-program":_vm.fetchProgram,"operation-mode":_vm.operationMode,"adk-type":_vm.currentAdk,"user-doc":_vm.userDoc},on:{"next-page":_vm.goNext,"isSaveProgress":function($event){_vm.isSaveProgress = true}},model:{value:(_vm.programDoc),callback:function ($$v) {_vm.programDoc=$$v},expression:"programDoc"}}),(
                (_vm.currentPage != _vm.maxIndex && _vm.operationMode !== _vm.OperationMode.Preview) ||
                _vm.windowWidth < 991
              )?_c('div',{staticClass:"guide__locks guide__locks--right",class:{
                locked: !_vm.isNextUnlocked,
                'unlocked-activity': _vm.isNextUnlocked,
                disabled: _vm.currentPage === _vm.maxIndex
              },on:{"click":function($event){_vm.windowWidth < 991 ? _vm.nextPage : ''}}},[_c('v-icon',{staticClass:"guide__lock",attrs:{"x-large":"","color":"grey darken-3","disabled":_vm.currentPage === _vm.adks && _vm.adks.length >= 1
                    ? _vm.adks.length - 1
                    : 0 || !_vm.isNextUnlocked},on:{"click":_vm.nextPage}},[_vm._v("mdi-chevron-double-right")]),_c('v-tooltip',{attrs:{"dark":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(
                      _vm.operationMode === _vm.OperationMode.Participant ||
                      _vm.operationMode === _vm.OperationMode.Monitor
                    )?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock mr-2 perform-adks-next",attrs:{"x-large":"","color":!_vm.isNextUnlocked ? 'red' : 'green'}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.isNextUnlocked ? 'mdi-lock-open' : 'mdi-lock'))]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.isNextUnlocked ? 'Unlocked' : 'Locked')+" Activity")])]),_c('v-tooltip',{attrs:{"dark":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(_vm.operationMode === _vm.OperationMode.Setup)?_c('v-icon',_vm._g(_vm._b({staticClass:"guide__lock mr-2 setup-adks-next",attrs:{"x-large":"","color":"#f79961"},on:{"click":_vm.nextPage}},'v-icon',attrs,false),on),[_vm._v("mdi-progress-wrench")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Setup Next Activity")])])],1):_vm._e()],1),_c('Overlay',{directives:[{name:"show",rawName:"v-show",value:(_vm.shouldDisplayOverlay),expression:"shouldDisplayOverlay"}],on:{"goto:last-setup":function($event){return _vm.handleChangeStep('last-setup')},"goto:last":function($event){return _vm.handleChangeStep('last')},"close":_vm.handleCloseOverlay,"goto:next":function($event){return _vm.handleChangeStep('next')},"goto:next-setup":function($event){return _vm.handleChangeStep('next-setup')}}})],2):_vm._e()])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }