






import { useDbGetters, useUserState, useUserActions } from '@/store';
import { watchEffect } from '@vue/composition-api';
import { ObjectId } from 'bson';
import activitiesList from './const';

export default {
  props: {
    activityName: {
      type: String,
      required: false,
      default: ''
    }
  },
  setup(_props, ctx: any) {
    const { collection } = useDbGetters(['collection']);
    const { user }: any = useUserState(['user']);
    const { getLatestDocumentByUserId } = useUserActions(['getLatestDocumentByUserId']);
    const { activityName }: any = _props;
    async function redirect() {
      // collection.value!('Program')
      //   .findOne({ organizers: new ObjectId(user.value._id) }, { sort: { dateCreated: -1 } })
      //   .then(res => {
      //     if (res) {
      //       const adks = activitiesList;
      //       let index = 0;
      //       if (adks.includes(activityName.toLowerCase())) {
      //         index = adks.indexOf(activityName.toLowerCase());
      //       }
      //       console.log(index, 'index');
      const res = await getLatestDocumentByUserId({
        document_type: 'Program',
        user_id: user.value._id.toString()
      });
      if (res && res?.document && res?.document?.adks && res?.document?.adks?.length >= 1) {
        const mapedAdks = res?.document?.adks?.map(adk => adk?.name);
        let index = 0;
        if (mapedAdks.includes(activityName.toLowerCase())) {
          index = mapedAdks.indexOf(activityName.toLowerCase());
        }
        ctx.root.$router.push({
          name: 'guide',
          params: { programId: res.document._id.toString(), page: index }
        });
      } else {
        ctx.root.$router.push({ name: 'portfolio' });
      }
      // });
    }

    watchEffect(async () => {
      if (user.value?._id && activityName) {
        await redirect();
      }
    });

    return { redirect };
  }
};
