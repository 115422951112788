































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  props: {
    color: {
      type: String,
      default: 'green'
    },
    content: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    background: {
      type: String,
      required: true
    },
    rightLogo: {
      type: String,
      default: ''
    }
  },

  setup(props) {}
});
