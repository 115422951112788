export enum StudentStatus {
  notAutoApplied = 0,
  awaitingInterview,
  awaitingOffer,
  organizerShelvedStudent,
  organizerMadeOffer,
  studentDeclinedOffer,
  studentAcceptedOffer
}

export function getStudentStatus(studentDoc: any): StudentStatus {
  const interview = studentDoc?.adks?.find(adk => adk.name === 'interview');
  if (interview?.completed && !studentDoc.offerDetails && !studentDoc.applicationStatus)
    return StudentStatus.awaitingOffer;

  const offer = studentDoc?.adks?.find(adk => adk.name === 'offer');
  if (!offer && !interview) return StudentStatus.notAutoApplied;

  const offerAdkMolecule = offer?.fields?.find(field => field.name === 'm-offer-table');
  if (offer?.completed && offerAdkMolecule?.value?.offerStatus)
    // if (offer?.completed && offerAdkMolecule?.value?.offerStatus && interview?.completed)
    return StudentStatus.studentAcceptedOffer;
  if (offer?.completed) return StudentStatus.studentDeclinedOffer;

  if (studentDoc.offerDetails && !studentDoc?.offerDetails?.rejectionReason && !offer?.completed)
    return StudentStatus.organizerMadeOffer;
  if (studentDoc.applicationStatus && !offer?.completed)
    return StudentStatus.organizerShelvedStudent;

  return StudentStatus.notAutoApplied;
}
